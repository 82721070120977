<template>
  <div class="overflow-hidden">
    <Header></Header>
    <div class="wrapper-home-page">
      <div class="flex-container">
        <div class="text-center">
          <!--        <div class="">-->
          <!--         >-->
          <!--        </div>-->
<!--          <h1 class="home-title">Is your property fairly assessed?</h1>-->
<!--          <h3  class="home-subtitle">Let’s find out!</h3>-->
          <div class="wrap-illustration-circle">
            <img class="illustration-circle" src="../assets/logo_taxert.png" alt="">
<!--            <img class="illustration-circle" src="../assets/logo_and_lines.png" alt="">-->
          </div>
          <h1 class="title">Take control of your <br />
            property taxes</h1>
          <SearchComponent :order="'page top search'"></SearchComponent>
        </div>
      </div>
    </div>
    <NewYorkCityPropertyTaxes></NewYorkCityPropertyTaxes>
    <TaxertResolves></TaxertResolves>
    <SuperbAnalytics></SuperbAnalytics>
    <HowItWorks></HowItWorks>
    <ToFindOut></ToFindOut>
    <Footer></Footer>
  </div>
</template>

<script>

import SearchComponent from '../components/SearchComponent.vue';
import Header from "../components/Header";
import NewYorkCityPropertyTaxes from "../components/NewYorkCityPropertyTaxes";
import TaxertResolves from "../components/TaxertResolves";
import SuperbAnalytics from "../components/SuperbAnalytics";
import HowItWorks from "../components/HowItWorks";
import ToFindOut from "../components/ToFindOut";
import Footer from "../components/Footer";

export default {
  name: 'Home',
  components: {
    SearchComponent,
    Header,
    NewYorkCityPropertyTaxes,
    TaxertResolves,
    SuperbAnalytics,
    HowItWorks,
    ToFindOut,
    Footer,
  },
  created() {
    this.$store.commit('resetState')
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "../scss/variables.scss";
.main-logo-img{
  width: 194px;
}
.nf-title{
  height: 19px;
  width: 202px;
  color: rgba(4,20,41,1);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 42px;
  margin-bottom: 8px;
}
.nf-descr{
  height: 42px;
  width: 309px;
  color: rgba(93,113,139,1);
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 40px;
}
.wrapper-home-page {
  width: 100vw;
  min-height: calc(100vh - 73px);
  background: url("../assets/bg.png") no-repeat;
  //background: url("../assets/bg_search-min.jpg") no-repeat;
  background-size: cover;
}

body {
  margin: 0;
}
.flex-container {
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  /*align-items: center;*/ /* если надо будет позиционировать всё по центру */
  justify-content: center;
}
.home-title {
  color: $font-black;
  font-family: "Open Sans";
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 50px;
  margin-bottom: 8px;
  margin-top: 78px;

}
.home-subtitle {
  color: $font-black;
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 35px;
  margin-bottom: 80px;
}



.wrapper-address-details {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: space-between;
}
.borough {
  width: 220px !important;
}
/*
New classes
*/
.wrap-illustration-circle {
  padding-top: 97px;
  padding-bottom: 36px;
}
.illustration-circle {
  width: 194px;
}
.title {
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 64px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 72px;
  text-align: center;
  margin-bottom: 42px;
}
@media screen and (max-width: 768px) {
  .wrap-illustration-circle {
    padding-top: 80px;
    padding-bottom: 64px;
    position: center;
  }
  .illustration-circle {
    height: 40px;
    width: 170px;
  }
  .title {
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 52px;
    font-weight: 300;
    line-height: 64px;
    margin-bottom: 64px;
  }
  .wrapper-home-page {
    min-height: calc(80vh - 63px);
    background-position: bottom;
  }
}
@media screen and (max-width: 420px) {
  .wrap-illustration-circle{
    display: none;
  }
  .title{
    height: 98px;
    width: 324px;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 49px;
    text-align: center;
    margin:34px auto 40px;
  }
}
@media screen and (max-width: 375px) {
  .wrap-illustration-circle{
    display: none;
  }
  .title{
    height: 98px;
    font-weight: 300;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 34px;
    width: 288px;
    color: #0A1250;
    font-size: 32px;
    letter-spacing: -0.36px;
    line-height: 40px;
  }
}
@media screen and (max-width: 320px) {
  .wrap-illustration-circle{
    display: none;
  }
  .title{
    height: 98px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 34px;
    width: 288px;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: -0.36px;
    line-height: 40px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .help-btn {
    width: 100% !important;
    height: 40px;
    line-height: 15px;
    padding: 10px 27px;
  }
  @media screen and (min-width: 320px) and (max-width: 374px) {
    .help-btn {
      width: 100% !important;
    }
  }
}
</style>
