<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import VueMeta from 'vue-meta'
  import Vue from 'vue'
  Vue.use(VueMeta)

  export default {
    name: 'App',
    beforeCreate() {
      console.log('beforeCreate APP.vue' , Date.now())
    },
    created() {
      console.log('created APP.vue' , Date.now())
    },
    beforeMount() {
      console.log('beforeMount APP.vue' , Date.now())
    },
    mounted() {
      console.log('mounted APP.vue' , Date.now())
        let inspectletScript = document.createElement('script')

      let inlineScript = document.createTextNode( '(function() {window.__insp = window.__insp || [];\n' +
            '__insp.push([\'wid\', 1368102343]);var ldinsp = function(){if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement(\'script\'); insp.type = \'text/javascript\'; insp.async = true; insp.id = "inspsync"; insp.src = (\'https:\' == document.location.protocol ? \'https\' : \'http\') + \'://cdn.inspectlet.com/inspectlet.js?wid=1368102343&r=\' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName(\'script\')[0]; x.parentNode.insertBefore(insp, x); };\n' +
            'setTimeout(ldinsp, 0);})()')
        inspectletScript.appendChild(inlineScript);
        document.head.appendChild(inspectletScript)
    },
    metaInfo() {
      return {
        meta: [
          { name: 'viewport', content:  'width=device-width, initial-scale=1, maximum-scale=1'},
        ]
      }
    },
  }
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

body {
  font-family: "Open Sans" !important;
}
</style>
