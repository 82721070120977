<template>
  <div class="wrapper-how-it-works">
    <h2 class="how-it-works-title text-center" id="how_it_work">How it works</h2>

    <h5  class="how-it-works-subtitle text-center">A few simple steps to find out is your property over-assessed </h5>
    <div class="wrapper-how-it-works-steps">
      <div class="d-flex how-it-works-steps-item position-relative">
        <div class="how-it-works-container">
          <img class="how-it-works-steps-item-number-img img-small"  src="../assets/number_1.png" alt="">
          <img class="how-it-works-steps-item-title-img" src="../assets/ill_5.png" alt="">
        </div>
        <img class="how-it-works-steps-item-number-img " id="x" src="../assets/number_1.png" alt="">
        <svg class="position-absolute first-line" :height="530" width="3" :style="{top: position_first.y, left: position_first.x}">
          <line x1="0" y1="0" x2="0" :y2="530" style="stroke:#F4F8FC;stroke-width:3" />
        </svg>
        <svg class="position-absolute line" :height="475" width="3" :style="{top: position_first.y, left: position_first.x}">
          <line x1="0" y1="0" x2="0" :y2="475" style="stroke:#F4F8FC;stroke-width:3" />
        </svg>
        <div class="how-it-works-steps-item-text">
          <h4>Find your property</h4>
          <p>Look up by the address or the block and lot. </p>
        </div>
      </div>
      <div class="d-flex how-it-works-steps-item position-relative">
        <div class="how-it-works-container">
          <img class="how-it-works-steps-item-number-img img-small" src="../assets/number_2.png" alt="">
          <img class="how-it-works-steps-item-title-img" src="../assets/ill_6.png" alt="">
        </div>
        <img id="y" class="how-it-works-steps-item-number-img " src="../assets/number_2.png" alt="">
<!--        <svg class="position-absolute line" :height="distance_second" width="3" :style="{top: position_second.y, left: position_second.x}">-->
<!--          <line x1="0" y1="0" x2="0" :y2="distance_second" style="stroke:#F4F8FC;stroke-width:3" />-->
<!--        </svg>-->
        <div class="how-it-works-steps-item-text">
          <h4>Provide income and expense info</h4>
          <p>In order to analyze the property’s assessment, income and expense data is the most valuable.</p>
        </div>
      </div>
      <div class="d-flex how-it-works-steps-item position-relative">
        <div class="how-it-works-container">
          <img id="z" class="how-it-works-steps-item-number-img img-small" src="../assets/number_4.png" alt="">
          <img class="how-it-works-steps-item-title-img" src="../assets/ilk_7.png" alt="">
        </div>
        <img id="z" class="how-it-works-steps-item-number-img " src="../assets/number_4.png" alt="">
<!--        <svg class="position-absolute line" :height="distance_third" width="3" :style="{top: position_third.y, left: position_third.x}">-->
<!--          <line x1="0" y1="0" x2="0" :y2="distance_third" style="stroke:#F4F8FC;stroke-width:3" />-->
<!--        </svg>-->
        <div class="how-it-works-steps-item-text">
          <h4>Provide vacancy and owner-occupancy information</h4>
          <p>Accurate assessment can only be performed when taking into account the unique circumstances of the property
            in question.</p>
        </div>
      </div>
      <div class="d-flex how-it-works-steps-item position-relative">
        <div class="how-it-works-container">
          <img id="w" class="how-it-works-steps-item-number-img img-small " src="../assets/number_3.png" alt="">
          <img class="how-it-works-steps-item-title-img" src="../assets/ill_8.png" alt="">
        </div>
        <img id="w" class="how-it-works-steps-item-number-img " src="../assets/number_3.png" alt="">
        <div class="how-it-works-steps-item-text">
          <h4>Receive initial report!</h4>
          <p>Enjoy the report that provides the analytics and the action plan for your property taxes.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function getPositionAtCenter(element) {
  const {top, left, width, height} = element.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2 + 4
  };
}
function getPositionAtCenterByParent(element) {
  return {
    x: element.offsetLeft + 18,
    y: element.offsetTop + 28
  };
}

function getDistanceBetweenElements(a, b) {
  const aPosition = getPositionAtCenter(a);
  const bPosition = getPositionAtCenter(b);

  return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
}

export default {
  name: "HowItWorks",
  data: () => ({
    distance_first: 0,
    position_first: 0,
    distance_second: 0,
    position_second: 0,
    distance_third: 0,
    position_third: 0,
  }),
  mounted(){
    this.distance_first = getDistanceBetweenElements(
        document.getElementById("x"),
        document.getElementById("y")
    );
    this.position_first = getPositionAtCenterByParent(
        document.getElementById("x")
    );
    this.distance_second = getDistanceBetweenElements(
        document.getElementById("y"),
        document.getElementById("z")
    );
    this.position_second = getPositionAtCenterByParent(
        document.getElementById("y")
    );
    this.distance_third = getDistanceBetweenElements(
        document.getElementById("z"),
        document.getElementById("w")
    );
    this.position_third = getPositionAtCenterByParent(
        document.getElementById("z")
    );

  }
}
</script>

<style scoped>
.img-small{
  display: none;
}
.wrapper-how-it-works {
  padding-top: 240px;
}
.how-it-works-title {
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 64px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 64px;
  margin-bottom: 16px;
}
.how-it-works-subtitle {
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
  margin-bottom: 80px;
}
.wrapper-how-it-works-steps {
  max-width: 690px;
  margin: 0 auto;
}
.how-it-works-steps-item {
  margin-bottom: 73px;
}
.how-it-works-steps-item-title-img {
  width: 100px;
  height: 100px;
}
.how-it-works-steps-item-title-img img{
  width: 100px;
}
.how-it-works-steps-item-number-img {
  width: 36px;
  height: 36px;
  margin: 38px 50px 0;
}
.how-it-works-steps-item-text {
  padding-top: 19px;
}
.how-it-works-steps-item-text h4{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 33px;
  margin-bottom: 6px;
}
.how-it-works-steps-item-text p{
  color: #546C92;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .first-line{
    display: none;
  }
  .wrapper-how-it-works {
    padding-top: 160px;
  }
  .how-it-works-title {
    font-size: 44px;
    line-height: 64px;
    margin-bottom: 12px;
  }
  .how-it-works-subtitle {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 64px;
  }
  .how-it-works-steps-item {
    margin-bottom: 55px;
  }
  .how-it-works-steps-item-text {
    padding-top: 17px;
  }
  .how-it-works-steps-item-text h4{
    font-size: 21px;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 6px;
  }
  .how-it-works-steps-item-text p{
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .wrapper-how-it-works-steps {
    max-width: 672px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 420px) {
  .wrapper-how-it-works {
    padding-top: 120px;
  }
  .line{
    display: none;
  }
  .how-it-works-steps-item-number-img{
    display: none;
    margin: 40px 3px 0px -40px;
  }
  .img-small{
    display: block;
  }
  .how-it-works-title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 12px;
  }
  .how-it-works-steps-item{
    flex-direction: column;
    margin-bottom: 48px;
  }
  .how-it-works-container{
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .how-it-works-subtitle {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 64px;
    width:244px;
    margin-left: auto;
    margin-right: auto;
  }
  .how-it-works-steps-item-text{
    width: 340px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .how-it-works-steps-item-text h4{
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 4px;
  }
  .how-it-works-steps-item-text p{
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
}
@media screen and (max-width: 375px) {
  .wrapper-how-it-works{
    padding-top: 100px;
  }
  .how-it-works-title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 320px) {
  .how-it-works-steps-item-text{
    width: 320px;
    padding: 10px;
  }
}

</style>
