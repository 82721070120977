<template>
  <div class="wrapper-form">
    <Preloader v-if="is_loading"></Preloader>
    <div v-if="adress_not_found">
      <p class="nf-title">We couldn't find this property</p>
      <p class="nf-descr">Unfortunately, we couldn't find anything.
        Please check if the data is correct and try again.</p>
      <ButtonDefault @click="backToSearch" :need_forward="false" text="Try again!"/>
    </div>
    <div v-else>
      <InputAutocomplete id="searchAddress" placeholder="Search by the address" :empty_search="empty_search" :autocomplete="searchAddressAutocomplete" @inputTyping="inputTyping" @selectList="inputSelected" />
      <div class="wrapper-or-block">
        <span class="or-block">Or</span>
      </div>
      <CustomSelect class="borough-mob" id="borough" placeholder="Select borough" inputClass="borough" :autocomplete="boroughAutocomplete" @selectList="inputSelected" @handleFocus="handleFocus" />
      <div class="wrapper-address-details">
        <CustomSelect id="borough" placeholder="Select borough" inputClass="borough" class="borough-full" :autocomplete="boroughAutocomplete" @selectList="inputSelected" @handleFocus="handleFocus" />
        <InputDefault id="block" class="block-inp" placeholder="Block" @inputTyping="inputSelected" />
        <InputDefault id="lot" placeholder="Lot"  @inputTyping="inputSelected"/>
      </div>
      <ButtonDefault @click="getBBLData" :disabled="!form.searchAddress &&  (!form.borough || !form.block || !form.lot)" text="Search for property!"/>
    </div>
  </div>
</template>

<script>
import InputDefault from "@/components/InputDefault";
import InputAutocomplete from "@/components/InputAutocomplete";
import CustomSelect from "@/components/CustomSelect";
import ButtonDefault from "@/components/ButtonDefault";
import Preloader from "@/components/Preloader";
import axios from "axios";

export default {
name: "SearchComponent",
  components: {
    InputDefault,
    InputAutocomplete,
    CustomSelect,
    ButtonDefault,
    Preloader,
  },
  props: {
    order: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    // cors_url : 'https://cors-anywhere.herokuapp.com/',
    cors_url : '',
    adress_not_found : false,
    empty_search : false,
    is_loading : false,
    searchAddressAutocomplete: [],
    boroughAutocomplete: ["Manhattan" , "Bronx", "Brooklyn", "Queens", "Staten Island"],
    borough_codes:[
      {name: "Bronx", value: 2},
      {name: "Manhattan", value: 1},
      {name: "Brooklyn", value: 3},
      {name: "Queens", value: 4},
      {name: "Staten Island", value: 5},
    ],
    form: {
      searchAddress: null,
      borough: null,
      block: null,
      lot: null,
    }
  }),
  methods: {
    async inputTyping({inputId, inputValue}) {
      this.inputSelected({inputId, inputValue});
      if(inputValue.length > 3  && inputId !== 'borough') {
        let url = `https://old.portal.mgnyconsulting.com:8091/API/PublicPortal/getPropertyAddressAuto/${inputValue}`
        const { data } = await axios.get(this.cors_url + url);
        this[`${inputId}Autocomplete`] = data;
        this.empty_search = data && data.length === 0

      } else if (inputValue.length === 0) {
        this[`${inputId}Autocomplete`] = [];
      }
    },
    backToSearch(){
      this.adress_not_found = false;
      this.form = {
        searchAddress: null,
        borough: null,
        block: null,
        lot: null,
      }
    },
    track(){
      this.$gtag.event('Search event', {
        'event_category': this.order,
        'value': this.form.searchAddress ? 'Address search' : this.form.block && this.form.lot && this.form.borough ? 'BBL search' :  'Address and BBL search',
      })
    },
    async getBBLData(){
      this.track()
      let params ={
        "block": this.form.block,
        "boroughCode": this.form.borough ? this.borough_codes.find(item=>item.name === this.form.borough).value : '',
        "by_address": this.form.searchAddress,
        "easement": null,
        "lot": this.form.lot
      };

      let url = 'https://old.portal.mgnyconsulting.com:8091/API/PublicPortal/BBLBasicData'
      this.is_loading = true
      await axios.post(this.cors_url + url , params)
          .then(response=>{
            if(response.status ===  200 && response.data && response.data.id){
              // this.$store.commit('setGuid', { guid: this.uuid(),})
              this.$store.commit('setAddress', {
                address: response.data['shortAddress'],
                block: response.data['block'],
                boroughCode: response.data['boroughCode'],
                currentFiscalYear: response.data['lastFiscalYears']['currentFiscalYear'] ,
                lot: response.data['lot'],
                neighborhoodName: response.data['neighborhoodName'],
                buildingClassDescription: response.data['buildingClassDescription'],
                bblId: response.data['id']})
              this.$router.push({name: 'step' , params:{id : response.data.id}})
              this.$store.dispatch('SendInfo')
            }
          })
          .catch(error=> {
            if (error.response) {
              if(error.response.status === 404 && error.response.data.Message === "This BBLE was not found!" ){
                this.adress_not_found = true;
              }
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }

          })
      this.is_loading = false
    },
    uuid() {
      return 'txxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    inputSelected({inputId, inputValue}) {
      this.form[`${inputId}`] = inputValue;
    },
    handleFocus({inputId}) {
      this[`${inputId}Autocomplete`] = ["Manhattan","Bronx","Brooklyn","Queens", "Staten Island"];
    }
  }
}
</script>

<style lang="scss">
@import "../scss/variables.scss";
.borough-mob{
  display: none;
}
.or-block {
  display: block;
  height: 60px;
  width: 100%;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;
  position: relative;
  margin: 0 auto;
}
.or-block:before,
.or-block:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 5;
  height: 1px;
  width: 246px;
  background-color: $white-color;
  top: 30px;
}
.or-block:before {
  left: 0;
}
.or-block:after {
  right: 0;
}
.wrapper-form {
  height: 272px;
  width: 656px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(26,41,60,0.15);
  padding: 24px;
  position: relative;
}
@media screen and (max-width: 768px) {
  wrap-header {
    max-width: 1320px;
    height: 63px;
    margin: 0 auto;
    padding: 24px 24px;
    border-bottom: 1px solid  #E6E8FF;
    overflow: hidden;
  }
}
@media screen and (max-width: 420px) {
  .wrapper-form {
    height: 296px;
    width: 390px;
    border-radius:0;
    padding: 16px;
  }
  .or-block {
    height: 44px;
    width: 100%;
    color: $font-gray;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 44px;
    position: relative;
    margin: 0
  }
  .or-block:before,
  .or-block:after {
    height: 1px;
    width: 155px;
    top: 22px;
  }
  .borough-mob{
    display: block;
  }
  .borough-full{
    display: none;
  }
  .custom-select_input__wrapper{
    margin-bottom: 16px;
  }
  .block-inp{
    margin-left: 0!important;
  }
}
@media screen and (max-width: 375px) {
  .wrapper-form {
    height: 296px;
    width: 375px;
    border-radius:0;
    padding: 16px;
  }
  .block-inp{
    margin-left: 0!important;
  }
}
@media screen and (max-width: 325px) {
  .wrapper-form {
    height: 296px;
    width: 320px;
    border-radius:0;
    padding: 16px;
  }
  .or-block:before,
  .or-block:after {
    height: 1px;
    width: 120px;
    top: 22px;
  }
  .block-inp{
    margin-left: 0!important;
  }
}
</style>