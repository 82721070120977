<template>
  <div class="wrapper-tax-purpose">
    <h4 class="tax-purpose-title">Adjust the numbers to where they need to be</h4>
    <h2 class="tax-purpose-hint">If you don't remember the exact amounts, give us approximate values.</h2>

    <div class="tax-purpose-wrap">
      <div class="tax-purpose-item">
        <img src="../assets/img_Gross_Annual_Income.png" alt="" class="tax-purpose-image">
        <p class="tax-purpose-text">Gross Annual Income</p>
        <p v-if="income_client !== null" class="tax-purpose-sum">{{ `$${income_client.toLocaleString()}` }}</p>

        <div class="flex-column justify-content-start sum-box">
          <p class="tax-purpose-text-box">Gross Annual Income</p>
          <p v-if="income_client !== null" class="tax-purpose-sum-box">{{ `$${income_client.toLocaleString()}` }}</p>
        </div>

      </div>
      <CustomInputRange id="income_client-selected" @slider_change="slider_change" @slider_input="slider_input" :min_value="0" :max_value="income_max_value" :selected_value="income_client"></CustomInputRange>
    </div>

    <div class="tax-purpose-wrap">
      <div class="tax-purpose-item">
        <img src="../assets/img_Expenses_(before_Re_taxes).png" alt="" class="tax-purpose-image">
        <p class="tax-purpose-text">Expenses (before Re taxes)</p>
        <p  v-if="expenses_client !== null" class="tax-purpose-sum">{{ `$${expenses_client.toLocaleString()}` }}</p>

        <div class="flex-column justify-content-start sum-box">
          <p class="tax-purpose-text-box">Expenses (before Re taxes)</p>
          <p  v-if="expenses_client !== null" class="tax-purpose-sum-box">{{ `$${expenses_client.toLocaleString()}` }}</p>
        </div>
      </div>
      <CustomInputRange id="expenses_client-selected"  @slider_change="slider_change" @slider_input="slider_input" :min_value="0" :max_value="expenses_max_value" :selected_value="expenses_client"></CustomInputRange>
    </div>
    <div class="tra"></div>
    <div class="tax-purpose-wrap">
      <div class="tax-purpose-item">
        <img src="../assets/img_gross_floor_area.png" alt="" class="tax-purpose-image">
        <p class="tax-purpose-text">Gross Floor Area (sf)</p>
        <p v-if="area_client !== null" class="tax-purpose-sum">{{ `${area_client.toLocaleString()}` }}</p>

        <div class="flex-column justify-content-start sum-box">
          <p class="tax-purpose-text-box">Gross Floor Area (sf)</p>
          <p v-if="area_client !== null" class="tax-purpose-sum-box">{{ `${area_client.toLocaleString()}` }}</p>
        </div>
      </div>
      <CustomInputRange id="area_client-selected"  @slider_change="slider_change" @slider_input="slider_input" :min_value="0" :max_value="area_max_value" :selected_value="area_client"></CustomInputRange>
    </div>
    <div class="wrapper-button">
      <ButtonDefault @click="handleClickResult()" text="Done!"/>
    </div>
  </div>
</template>

<script>
import ButtonDefault from '../components/ButtonDefault';
import CustomInputRange from '../components/CustomInputRange';
import {mapGetters} from "vuex";
export default {
  name: "TaxPurpose",
  components: {
    ButtonDefault,
    CustomInputRange
  },
  beforeCreate() {
    console.log('beforeCreate TaxPurposeSettings.vue' , Date.now())
  },
  created() {
    this.change_count = 0
    console.log('created TaxPurposeSettings.vue' , Date.now())
  },
  beforeDestroy() {
    this.change_count = 0
  },
  beforeMount() {
    console.log('beforeMount TaxPurposeSettings.vue' , Date.now())
  },
  mounted() {
    console.log('mounted TaxPurposeSettings.vue' , Date.now())
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  computed: {
    ...mapGetters({
      income: 'get_income',
      income_client: 'get_income_client',
      expenses_client: 'get_expenses_client',
      expenses: 'get_expenses',
      area_client: 'get_area_client',
      area: 'get_area',
    }),
  },
  watch: {
    income: {
      immediate: true,
      handler(newVal){
        if(newVal !== 0){
          this.income_max_value = newVal * 2;
        }else{
          this.income_max_value= 999999;
        }
      }
    },
    expenses: {
      immediate: true,
      handler(newVal){
        if(newVal !== 0){
          this.expenses_max_value = newVal * 2;
        }else{
          this.expenses_max_value= 999999;
        }
      }
    },
    area: {
      immediate: true,
      handler(newVal){
        if(newVal !== 0){
          this.area_max_value = newVal * 2;
        }else{
          this.area_max_value= 999999;
        }
      },
    },
  },
  data: () => ({
    income_selected: '',
    expenses_selected: '',
    area_selected: '',
    income_max_value: 999999,
    expenses_max_value: 999999,
    area_max_value: 999999,
    change_count : 0
  }),
  methods:{
    track(){
      this.$gtag.event('Slider change', {
        'event_category': "tax-purpose-settings",
        'value': this.change_count - 3  // because initial causes update
      })
    },
    async handleClickResult(){
      this.track()
      let user_selection = [
        {income_selected: this.income_selected},
        {expenses_selected: this.expenses_selected},
        {area_selected: this.area_selected},
      ];
      console.log(user_selection);

      this.$store.dispatch('SendInfo')
      const res = {}
      res.status = 'ok'
      console.log(res.status);
      if(res.status === 'ok'){
        this.$router.push({name :'relationship.to.property'})
      }
    },
    slider_change(){
      this.change_count++
    },
    slider_input({ inputId, inputValue }){
      this[inputId] = Number(inputValue)
      this.$store.commit('changeInputRangeParams', {name: inputId.split('-')[0], value: Number(inputValue)})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.tax-purpose-sum-box{
  display: none;
}
.tra{
  width: 88%;
  height: 400px;
  position: absolute;
  bottom: -90px;
  display: none;
}
.sum-box{display: none}
.wrapper-tax-purpose {
  height: 568px;
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26,41,60,0.1);
  padding: 24px;
}
.tax-purpose-hint{
  height: 19px;
  width: 100%;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 24px;
}
.tax-purpose-title {
  height: 33px;
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  width: 100%;
  margin-bottom: 3px;
}
.tax-purpose-wrap{
  padding: 19px 20px;
  height: 120px;
  width: 824px;
  border-radius: 10px;
  background-color: $common-bg;
  margin-bottom: 8px;
  border: 1px solid $common-bg;
}
.tax-purpose-wrap:hover {
  border: 1px solid rgba(11,40,78,0.2);
  border-radius: 10px;
  background-color: transparent;
}
.tax-purpose-wrap:hover .tax-purpose-sum-box{
  color: $font-black;
}
.tax-purpose-wrap:hover .tax-purpose-sum{
  color: #082E60;
}
.tax-purpose-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.tax-purpose-image,
.tax-purpose-text {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}
.tax-purpose-image{
  height: 40px;
  width: 35px;
}
.tax-purpose-text {
  color: $font-black;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0;
  margin-left: 20px;
}
.tax-purpose-sum {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  text-align: right;
  color: $font-blue;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 0;
}
.wrapper-button {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 23px;
}
.wrapper-button > button {
  margin-top: 0;
  margin-right: 16px;
  width: 264px;
}
@media screen and (max-width: 768px) {
  .tax-purpose-wrap{
    width: 680px;
  }
}
@media screen and (max-width: 420px) {
  .wrapper-button > button {
    margin-top: 0;
    width: 100%;
    margin-right: 0;
  }
  .tra{
    display: block;
  }
  .sum-box{display: flex}
  .tax-purpose-text{display: none}
  .tax-purpose-sum{display: none}
  .tax-purpose-title{
    font-size: 19px;
    line-height: 28px;
    width: 349px;
    margin-left: auto;
    margin-right: auto;
    height: 57px;
  }
  .tax-purpose-hint{
    font-size: 12px;
    line-height: 17px;
    width: 281px;
    margin-right: auto;
    margin-left: auto;
  }
  .wrapper-tax-purpose {
    width: 390px;
    margin-top: 0px;
    padding: 16px;
    border-radius: 0;
    margin-left: auto;
    margin-right: auto;
    height: 576px;
  }
  .tax-purpose-wrap{
    width: 358px;
  }
  .tax-purpose-item{
    flex-direction: row-reverse;
    justify-content: space-between;
  }
    .tax-purpose-text-box {
    color: $font-black;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 4px;
  }
  .tax-purpose-sum-box {
    display: block;
    color: $font-blue;
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 0;
  }
}


@media screen and (max-width: 375px) {
  .wrapper-tax-purpose {
    width: 375px;
    padding: 16px;
    height: 576px;
  }
  .tax-purpose-title{
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    width: 270px;
  }
  .tax-purpose-hint{
    font-size: 11px;
    letter-spacing: 0;
    line-height: 15px
  }
  .tax-purpose-wrap{
    width: 343px;
    height: 103px;
    padding: 12px;
  }
  .tax-purpose-sum-box{
    font-size: 16px;
    line-height: 22px;
  }
  .tax-purpose-text-box{
    font-size: 11px;
    line-height: 15px;
  }
  .wrapper-input-range{
    margin-top: 8px;
  }
}

@media screen and (max-width: 320px) {
  .tax-purpose-title{
    width: 222px;
    height: 48px;
    margin-left: auto;
    margin-right: auto;
  }
  .tax-purpose-hint{
    font-size: 11px;
    letter-spacing: 0;
    line-height: 15px
  }
  .tax-purpose-wrap{
    width: 288px;
    height: 103px;
    padding: 12px;
  }
  .tax-purpose-sum-box{
    font-size: 16px;
    line-height: 22px;
  }
  .tax-purpose-text-box{
    font-size: 11px;
    line-height: 15px;
  }
  .wrapper-input-range{
    margin-top: 8px;
  }
  .wrapper-tax-purpose {
    height: 530px;
    width: 320px;
  }
}

</style>