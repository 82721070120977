<template>
  <div class="wrapper-tax-purpose mx-auto">
    <h4 class="tax-purpose-title">You had <span class="non-vacant-area">{{ `${non_vacant_area.toLocaleString()} `}}</span>sf of non-vacant space and some owner-occupied space.</h4>
    <h4 class="tax-purpose-title">Provide owner-occupied vs rented breakdown.</h4>

    <div class="tax-purpose-wrap">
      <div class="tax-purpose-item">
        <div class="owner-occ-wrap">
          <img src="../assets/img_home.png" alt="" class="tax-purpose-image">
          <p class="subtext">Owner occupied</p>
          <p v-if="owner_area_sf !== null" class="owner-occupied">{{ `${owner_area_sf.toLocaleString()} sf (${owner_area_percent}%)` }}</p>
        </div>

        <div class="rented-area-wrap">
          <img src="../assets/img_Gross_Annual_Income.png" alt="" class="rented-area-image">
          <p class="subtext rented">Rented</p>
          <p v-if="rented_area_sf !== null" class="rented-area-percent">{{ `${rented_area_sf.toLocaleString()} sf (${rented_area_percent}%)` }}</p>
        </div>
      </div>
      <CustomInputRange id="area" @slider_change="slider_change" @slider_input="slider_input"  :min_value="0" :max_value="100" :selected_value="owner_area_percent" class="purple-bg"></CustomInputRange>
    </div>
      <ButtonDefault  @click="handleClickResult()" text="Done!" />
  </div>
</template>

<script>
import ButtonDefault from "@/components/ButtonDefault";
import CustomInputRange from "@/components/CustomInputRange";
import {mapGetters} from "vuex";

export default {
  name: "PercentageVacantProperty",
  components: {
    ButtonDefault,
    CustomInputRange
  },
  computed: {
    ...mapGetters({
      non_vacant_area: 'get_non_vacant_area',
      rented_area_sf: 'get_rented_area_sf',
      owner_area_sf: 'get_owner_area_sf',
      owner_area_percent: 'get_owner_area_percent',
    }),
  },
  data: () => ({
    rented_area_percent: 0,
    change_count:0
  }),
  created(){
    this.slider_change({inputValue : this.owner_area_percent})
  },
  beforeDestroy() {
    this.change_count = 0
  },
  methods:{
    slider_change(){
      this.change_count++
    },
    slider_input({inputValue }){
      this.owner_area_percent_data = Number(inputValue);
      this.rented_area_percent = 100 - Number(inputValue);

      let owner_area_sf = Math.floor(this.non_vacant_area * (100 - this.rented_area_percent) / 100);
      let rented_area_sf  = Math.floor(this.non_vacant_area * (100 - this.owner_area_percent_data) / 100);

      this.$store.commit('changeInputRangeParams', {name: 'rented_area_sf', value: rented_area_sf});
      this.$store.commit('changeInputRangeParams', {name: 'owner_area_sf', value: owner_area_sf});
      this.$store.commit('changeInputRangeParams', {name: 'owner_area_percent', value: this.owner_area_percent_data});
    },
    track(){
      this.$gtag.event('Slider change', {
        'event_category': "owner-vs-breakdown",
        'value': this.change_count - 1  // because initial causes update
      })
    },
    async handleClickResult(){
      this.track()
      this.$store.dispatch('SendInfo')
      const res = {}
      res.status = 'ok'
      if(res.status === 'ok'){
        this.$router.push({name :'results'})
      }
    },
  }
}
</script>
<style lang="scss">
@import "../scss/variables.scss";
.purple-bg [type=range] {
  background-color: $font-purple !important;
  height: 2px;
}
</style>
<style lang="scss" scoped>
@import "../scss/variables.scss";
.rented-area-image {
  width:40px;
  height: 40px;
  float: right;
  margin-left: 20px;
}
.tax-purpose-image {
  width: 40px;
  float: left;
  margin-right: 20px;
}
.wrapper-tax-purpose {
  height: 312px;
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26,41,60,0.1);

  padding: 24px;
}
.tax-purpose-title {
  height: 33px;
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  width: 100%;
  margin-bottom: 1px;
}
.non-vacant-area{
  color: $font-blue;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 6px;
}

.owner-occupied{
  color: rgba(21,76,149,1);
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  line-height: 19px;
  margin-bottom: 0px;
  width: 250px;
}

.tax-purpose-hint{
  height: 19px;
  width: 100%;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 24px;
}

.btn-default {
  width: 264px;
  margin: 24px auto 0;
  display: block;
}
.tax-purpose-wrap{
  margin-top: 22px;
  padding: 19px 20px;
  height: 120px;
  width: 824px;
  border-radius: 10px;
  background-color: $common-bg;
  margin-bottom: 8px;
  border: 1px solid $common-bg;
}
.tax-purpose-wrap:hover {
  border: 1px solid rgba(11,40,78,0.2);
  border-radius: 10px;
  background-color: transparent;
}
.tax-purpose-wrap:hover .tax-purpose-sum{
  color: #082E60;
}
.tax-purpose-wrap:hover .trented-area-percent{
  color: #082E60;
}
.tax-purpose-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.rented-area-image,
.tax-purpose-text {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.tax-purpose-text {
  color:  $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 0;
  margin-left: 20px;
}
.subtext {
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 6px;
  margin-left: 20px;
}
.rented-area-percent {

  text-align: right;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0px;
  color: $font-purple;
}
.tax-purpose-sum {
  text-align: right;
  color: $font-blue;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0;
}
.tax-purpose-sum .or-condition{
  color: $font-gray;
  font-weight: normal;
}
.rented-area-wrap {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .tax-purpose-title {
    height: 33px;
    line-height: 30px;
  }
  .wrapper-tax-purpose {
    height: 310px;
    width: 720px;
    margin-top: 0px;
    padding: 20px;
  }
  .tax-purpose-hint{
    margin-bottom: 20px;
  }
  .btn-default{
    margin-top: 20px;
  }
  .tax-purpose-wrap{
    width: 680px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 420px) {
  .rented-area-percent {
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
  }
  .wrapper-tax-purpose {
    height: 400px;
    width: 390px;
    margin-top: 0px;
    padding: 16px;
    border-radius: 0;
  }
  .tax-purpose-title {
    height: 56px;
    width: 357px;
    font-size: 19px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
  }
  .non-vacant-area{
    color: #0A1250;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
  .tax-purpose-hint{
    height: 17px;
    width: 196px;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .tax-purpose-wrap{
    height: 167px;
    width: 358px;
    margin-bottom: 0;
  }
  .btn-default{
    width: 358px;
    margin-bottom: 8px;
  }
  .owner-occ-wrap{
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 130px;
  }
  .subtext {
    color: $font-gray;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 12px;
    margin-bottom: 4px;
    margin-left: 0;
  }
  .owner-occupied{
    font-size: 19px;
    font-weight: 600;
    line-height: 26px;
  }
  .rented-area-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    -webkit-order: unset;
    -ms-flex-order: unset;
    order: unset;
    -webkit-flex: unset;
    -ms-flex: unset;
    flex: unset;
    -webkit-align-self: unset;
    -ms-flex-item-align: unset;
    align-self: unset;
    text-align: unset;
  }
  .tax-purpose-item{
    justify-content: space-between;
  }
  .rented{
    margin-left: 45px;
    text-align: right;
  }
}

@media screen and (max-width: 375px) {
  .wrapper-tax-purpose {
    height: 404px;
    width: 375px;
  }
  .tax-purpose-title {
    height: 56px;
    width: 340px;
    font-size: 16px;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
  }
  .tax-purpose-wrap{
    width: 343px;
  }
  .btn-default{
    width: 343px;
  }
}
@media screen and (max-width: 320px) {
  .wrapper-tax-purpose {
    height: 404px;
    width: 320px;
  }
  .tax-purpose-title {
    height: 56px;
    width: 288px;
    font-size: 15px;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;

  }
  .non-vacant-area{
    color: #0A1250;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
  .tax-purpose-wrap{
    width: 288px;
  }

  .btn-default{
    width: 288px;
  }
}
</style>