<template>
  <div>
    <b-navbar  type="light" variant="custom-nav" class="d-flex justify-content-between bar">
      <div @click="goToStart()" class="logo">
        <img class="logo-img" src="../assets/Logo_taxert.svg" alt="">
        <img class="logo-img-sign" src="../assets/Logo_taxert_sign.svg" alt="">
      </div>
      <div class="p-bar">
        <ProgressBar ></ProgressBar>
      </div>
      <div class="profile d-flex align-items-center justify-content-center">
        <div class="button-bg">
          <img class="profile-img" src="../assets/icn_profile.svg" alt="">
        </div>
      </div>
    </b-navbar>
    <div class="passive-line"></div>
  </div>
</template>

<script>
import ProgressBar from '../components/ProgressBar.vue';

export default {
name: "NavBar",
  components: {
    ProgressBar,
  },
  methods:{
    goToStart(){
      this.$store.commit('resetCookies', this.$route.params.id)
      this.$store.commit('resetState')
      this.$router.push({name: 'Home'})
    }
  }
}
</script>

<style lang="scss" >
@import "../scss/variables.scss";
.logo-img{
  height: 24px;
  width: 112.5px;
  cursor: pointer;
  margin-bottom: 2px;
}
.profile-img{
  height: 16px;
  width: 16px;
  color: #96A8BE;
  margin: 9px 8px;
}
.logo {
  padding-left: 60px;
}
.profile{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(26,41,60,0.15);
margin-right: 60px;
}
.button-bg {
  height: 34px;
  width: 32px;
  border-radius: 20px;
  background-color: $common-bg;
}
.bg-custom-nav{
  height: 72px;
  background-color: #FFFFFF;
}
.passive-line{
  height: 1px;
  opacity: 0.2;
  border-radius: 1px;
  background-color: #96A8BE;
}
.logo-img-sign{
  display: none;
}
@media screen and (max-width: 768px) {
.logo-img-sign{
  display: block;
}
  .logo-img{
  display: none;
}
  .logo {
    padding-left: 24px;
  }
  .profile{
    margin-right: 24px;
  }
}
@media screen and (max-width: 420px) {
  .bar{
    box-shadow: 0 2px 4px 0 rgba(26,41,60,0.15);
  }
  .logo {
    padding-left: 0;
  }
  .profile{
    margin-right: 0;
  }
  .p-bar{
    display: none!important;
  }
  .bg-custom-nav{
    height: 56px;
  }
}
</style>