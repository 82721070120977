<template>
  <div class="wrapper-tax-purpose mx-auto">
    <h4 class="tax-purpose-title mx-auto">The NYC Finance Department uses the following estimates for tax purposes.</h4>
    <div class="tax-purpose-item">
      <img src="../assets/img_Gross_Annual_Income.png" alt="" class="tax-purpose-image">
      <p class="tax-purpose-text">Gross Annual Income</p>
      <p v-if="income_client !== null" class="tax-purpose-sum">${{ income_client.toLocaleString() }}</p>

      <div class="flex-column justify-content-start sum-box">
        <p class="tax-purpose-text-box">Gross Annual Income</p>
        <p v-if="income_client !== null" class="tax-purpose-sum-box">${{ income_client.toLocaleString() }}</p>
      </div>

    </div>
    <div class="tax-purpose-item">
      <img src="../assets/img_Expenses_(before_Re_taxes).png" alt="" class="tax-purpose-image">
      <p class="tax-purpose-text">Expenses (before Re taxes)</p>
      <p v-if="expenses_client !== null"  class="tax-purpose-sum">${{ expenses_client.toLocaleString() }}</p>

      <div class="flex-column justify-content-start sum-box">
        <p class="tax-purpose-text-box">Expenses (before Re taxes)</p>
        <p v-if="expenses_client !== null"  class="tax-purpose-sum-box">${{ expenses_client.toLocaleString() }}</p>
      </div>

    </div>
    <div class="tax-purpose-item">
      <img src="../assets/img_gross_floor_area.png" alt="" class="tax-purpose-image">
      <p class="tax-purpose-text">Gross Floor Area (sf)</p>
      <p v-if="area_client !== null" class="tax-purpose-sum">{{ area_client.toLocaleString() }}</p>

      <div class="flex-column justify-content-start sum-box">
        <p class="tax-purpose-text-box">Gross Floor Area (sf)</p>
        <p v-if="area_client !== null" class="tax-purpose-sum-box">{{ area_client.toLocaleString() }}</p>
      </div>

    </div>
    <h5 class="tax-purpose-footer-title" >Are these estimates correct?</h5>
    <div class="wrapper-button">
      <ButtonDefault @click="handleClickResult(1)" text="Not even close!" />
      <ButtonDefault @click="handleClickResult(2)" text="In the ballpark"/>
      <ButtonDefault @click="handleClickResult(3)" text="Spot on!"/>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import ButtonDefault from '../components/ButtonDefault';
import { mapGetters } from 'vuex'

export default {
  name: "TaxPurpose",
  components: {
    ButtonDefault,
  },
  computed: {
    ...mapGetters({
      income_client: 'get_income_client',
      expenses_client: 'get_expenses_client',
      area_client: 'get_area_client',
      get_all: 'get_all',

    }),
  },
  methods:{
   handleClickResult(type){
      // const res = await axios.post('url' , type)
    this.$store.dispatch('SendInfo')
      const res = {}
      res.status = 'ok'
     if(res.status === 'ok'){
       if(type === 3 ){
         this.$router.push({name :'relationship.to.property'})
       }
       else{
         this.$router.push({name :'tax.purpose-settings'})
       }

     }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.sum-box{display: none}
.wrapper-tax-purpose {
  height: 480px;
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26,41,60,0.1);
  padding: 24px;
}
.tax-purpose-title {
  height: 33px;
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
}
.tax-purpose-item {
  padding: 19px 20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 80px;
  width: 824px;
  border-radius: 10px;
  background-color: $common-bg;
  margin-bottom: 8px;
}
.tax-purpose-image,
.tax-purpose-text {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}
.tax-purpose-image{
  height: 40px;
  width: 35px;
}
.tax-purpose-text {
  color: $font-black;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0;
  margin-left: 20px;
}
.tax-purpose-sum {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  text-align: right;
  color: $font-blue;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 0;
}
.tax-purpose-footer-title {
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  margin: 24px 0;
}
.wrapper-button {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.wrapper-button > button {
  margin-top: 0;

  margin-right: 16px;
}
.wrapper-button > button:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .wrapper-tax-purpose {
    height: 487px;
    width: 720px;
    padding: 20px;
  }
  .tax-purpose-title {
    height: auto;
    width: 454px;
    margin-bottom: 20px;
  }
  .tax-purpose-item {
    padding: 19px 20px;
    height: 80px;
    width: 672px;
    margin-bottom: 8px;
  }
  .tax-purpose-footer-title {
    margin: 20px 0;
  }
}
@media screen and (max-width: 420px) {
  .wrapper-tax-purpose {
    height: 554px;
    width: 390px;
    margin-top: 0px;
    padding: 16px;
    border-radius: 0;
  }
  .tax-purpose-title {
    height: auto;
    width: 345px;
    margin-bottom: 20px;
    font-size: 19px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .tax-purpose-item {
    padding: 19px 20px;
    height: 72px;
    width: 358px;
    margin-bottom: 8px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .tax-purpose-footer-title {
    margin: 20px 0;
  }
  .sum-box{display: flex}
  .tax-purpose-text{display: none}
  .tax-purpose-sum{display: none}
  .tax-purpose-text-box {
    color: $font-black;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 4px;
  }
  .tax-purpose-sum-box {
    color: $font-blue;
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 0;
  }
  .tax-purpose-footer-title {
    margin: 20px 0;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
  }
  .wrapper-button {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .btn-default{
    width: 358px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 375px) {
  .wrapper-tax-purpose {
    height: 540px;
    width: 375px;
    margin-top: 0px;
    padding: 16px;
    border-radius: 0;
  }
  .tax-purpose-title {
    height: 33px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .tax-purpose-item {
    padding: 19px 20px;
    height: 72px;
    width: 343px;
    margin-bottom: 8px;

  }
  .tax-purpose-footer-title {
    margin: 20px 0;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .btn-default{
    width: 343px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 320px) {
  .wrapper-tax-purpose {
    height: 540px;
    width: 320px;
    margin-top: 0px;
    padding: 16px;
    border-radius: 0;
  }
  .tax-purpose-title {
    height: 72px;
    width: 255px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .tax-purpose-item {
    padding: 19px 20px;
    height: 68px;
    width: 288px;
    margin-bottom: 8px;

  }
  .tax-purpose-footer-title {
    margin: 20px 0;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .btn-default{
    width: 288px;
    margin-bottom: 8px;
  }
  .tax-purpose-text-box {
    font-size: 11px;
    line-height: 15px;
  }
  .tax-purpose-sum-box {
    font-size: 16px;
    line-height: 22px;
  }
}

</style>