<template>
  <div class="wrapper-nyc-property-taxes d-flex justify-content-center">
    <div class="d-flex tax-col">
      <img src="../assets/ill_block_2.png" alt="" class="ill_block">
      <div class="wrapper-nyc-property-taxes-text">
        <h2><span>New York City</span><br />Property Taxes:</h2>
        <ul>
          <li>#1 - Expense item for real estate owners</li>
          <li>#1 - Source of confusion for everyone involved</li>
          <li>#1 - Revenue source for the City</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewYorkCityPropertyTaxes"
}
</script>

<style scoped>
.wrapper-nyc-property-taxes {
  padding-top: 282px;
}
.ill_block {
  height: 510px;
  width: 520px;
  opacity: 0.9;
  margin-right: 64px;
}
.wrapper-nyc-property-taxes-text {
  padding-top: 115px;
}
.wrapper-nyc-property-taxes-text h2{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 64px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 80px;
  margin-bottom: 32px;
}
.wrapper-nyc-property-taxes-text h2 span{
  color: #104AB1;
}
.wrapper-nyc-property-taxes-text ul{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
}
.wrapper-nyc-property-taxes-text ul li{
  margin-bottom: 12px;
}
.wrapper-nyc-property-taxes-text ul li::marker{
  color: #104AB1;
}
@media screen and (max-width: 768px) {
  .wrapper-nyc-property-taxes {
    padding: 140px 24px 24px 0;
  }
  .ill_block {
    height: 322px;
    width: 317px;
    opacity: 1;
    margin: 0 18px 0 32px;
  }
  .wrapper-nyc-property-taxes-text {
    padding-top: 46px;
  }
  .wrapper-nyc-property-taxes-text h2{
    font-size: 44px;
    line-height: 56px;
    margin-bottom: 24px;
    margin-left: 22px;
  }
  .wrapper-nyc-property-taxes-text ul{
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .wrapper-nyc-property-taxes-text ul li{
    margin-bottom: 16px;
  }
  .wrapper-nyc-property-taxes-text ul li::marker{
    font-size: 16px;
  }
}
@media screen and (max-width: 420px) {
  .wrapper-nyc-property-taxes {
    padding: 77px 36px  0 36px;
  }
  .ill_block{
    height: 320px;
    width: 320px;
    opacity: 0.9;
    margin: 0;
  }
  .tax-col{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .wrapper-nyc-property-taxes-text h2{
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 42px;
    margin-bottom: 24px;
    text-align: center;
    margin-left: 0;
}
  .wrapper-nyc-property-taxes-text ul{
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    list-style: none;
    text-align: center;
    padding: 0;
  }
  .wrapper-nyc-property-taxes-text ul li{
    margin-bottom: 8px;
  }

}
@media screen and (max-width: 375px) {
  .ill_block{
    height: 310px;
    width: 305px;
    opacity: 0.9;
    margin: 0;
  }
  .wrapper-nyc-property-taxes-text {
    padding-top: 32px;
  }
  .wrapper-nyc-property-taxes {
    padding: 84px 27px  0 36px;
  }
  .wrapper-nyc-property-taxes-text h2{
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 320px) {

}
</style>
