import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import Cookies from 'js-cookie'
import axios from "axios";
Vue.use(Vuex)

const getDefaultState = () => {
  return {
    income: null, // Gross Annual Income
    guid: null, // ID
    income_client: null, // Gross Annual Income
    expenses: null, // Expenses (before Re taxes)
    expenses_client: null, // Expenses (before Re taxes)
    area: null, // Gross Floor Area (sf)
    area_client: null, // Gross Floor Area (sf)
    relationshipToProperty: null,  // checkbox on step 3
    vacanciesInTheLastTwentyMonths: null, // step 4 ?
    vacant_area: 0, // Vacancy on step 5
    ownerOccupancyInTheLastTwentyMonths: null, // step 6
    rented_area_sf: null, //step 7
    owner_area_sf: 0, //step 7
    owner_area_percent: 50, //step 7
    latitude: null,
    longitude: null,
    address: null,
    block: null,
    boroughCode: null,
    lot: null,
    currentFiscalYear: null,
    bblId: null,
    zoning: ' ',
    neighborhoodName: ' ',
    buildingClassDescription: ' ',
    scoreResults: null,
    resSent: [],
    tx_action: null,
    tx_reason: null

  }
}
export default new Vuex.Store({


  state: getDefaultState(),
  getters: {
    tx_action: state => state.tx_action,
    tx_reason: state => state.tx_reason,
    resSent: state => state.resSent,
    scoreResults: state => state.scoreResults,
    get_guid: state => state.guid ? state.guid : Cookies.get(`guid`) ? Cookies.get(`guid`) : null,
    get_income: state => state.income,
    get_income_client: state => state.income_client ?
        state.income_client : Cookies.get(`${router.currentRoute.params.id}income_client`) ?
            Number(Cookies.get(`${router.currentRoute.params.id}income_client`)) : state.income ? state.income : 0 ,
    get_expenses: state => state.expenses,
    get_expenses_client: state => state.expenses_client ?
        state.expenses_client : Cookies.get(`${router.currentRoute.params.id}expenses_client`) ?
            Number(Cookies.get(`${router.currentRoute.params.id}expenses_client`))
            : state.expenses ?   state.expenses : 0 ,
    get_area: state => state.area ,
    get_area_client: state => state.area_client ?
        state.area_client : Cookies.get(`${router.currentRoute.params.id}area_client`) ?
            Number(Cookies.get(`${router.currentRoute.params.id}area_client`)) : state.area ?
                state.area : 0 ,
    get_relationshipToProperty: state => state.relationshipToProperty ?
        state.relationshipToProperty : Cookies.get(`${router.currentRoute.params.id}relationshipToProperty`) ?
        Cookies.get(`${router.currentRoute.params.id}relationshipToProperty`) : null,
    get_vacanciesInTheLastTwentyMonths: state => state.vacanciesInTheLastTwentyMonths ? state.vacanciesInTheLastTwentyMonths : Cookies.get(`${router.currentRoute.params.id}vacanciesInTheLastTwentyMonths`) ?
        Cookies.get(`${router.currentRoute.params.id}vacanciesInTheLastTwentyMonths`) : null ,
    get_ownerOccupancyInTheLastTwentyMonths: state => state.ownerOccupancyInTheLastTwentyMonths ? state.ownerOccupancyInTheLastTwentyMonths : Cookies.get(`${router.currentRoute.params.id}ownerOccupancyInTheLastTwentyMonths`) ?
        Cookies.get(`${router.currentRoute.params.id}ownerOccupancyInTheLastTwentyMonths`) : null  ,
    get_vacant_area: state => state.vacant_area ? state.vacant_area : Cookies.get(`${router.currentRoute.params.id}vacant_area`) ?
        Number(Cookies.get(`${router.currentRoute.params.id}vacant_area`)) : 0,
    get_non_vacant_area: state =>  {
      let cl_area = state.area_client ?
          state.area_client : Cookies.get(`${router.currentRoute.params.id}area_client`) ?
              Number(Cookies.get(`${router.currentRoute.params.id}area_client`)) : state.area ?
                  state.area : null;
      let vac_area = state.vacant_area ? state.vacant_area : Cookies.get(`${router.currentRoute.params.id}vacant_area`) ?
          Number(Cookies.get(`${router.currentRoute.params.id}vacant_area`)) : null
       return cl_area - vac_area
    },
    get_rented_area_percent: state => state.rented_area_percent,
    get_rented_area_sf: state => state.rented_area_sf ? state.rented_area_sf : Cookies.get(`${router.currentRoute.params.id}rented_area_sf`) ?
        Number(Cookies.get(`${router.currentRoute.params.id}rented_area_sf`)) : 0,
    get_owner_area_percent: state => state.owner_area_percent ? state.owner_area_percent : Cookies.get(`${router.currentRoute.params.id}owner_area_percent`) ?
        Number(Cookies.get(`${router.currentRoute.params.id}owner_area_percent`)) : null,
    get_owner_area_sf: state => state.owner_area_sf ? state.owner_area_sf : Cookies.get(`${router.currentRoute.params.id}owner_area_sf`) ?
        Number(Cookies.get(`${router.currentRoute.params.id}owner_area_sf`)) : 0,
    // get_owner_area_sf: state => state.owner_area_sf ?
    //     state.owner_area_sf : Cookies.get(`${router.currentRoute.params.id}owner_area_sf`) ?
    //         Number(Cookies.get(`${router.currentRoute.params.id}owner_area_sf`)) : state.owner_area_sf ?
    //             state.owner_area_sf : null  ,
    get_latitude: state => state.latitude,
    get_longitude: state => state.longitude,

    get_address: state => state.address,
    get_block: state => state.block,
    get_boroughCode: state => state.boroughCode,
    get_lot: state => state.lot,
    get_currentFiscalYear: state => state.currentFiscalYear,
    get_bblId: state => state.bblId,
    get_zoning: state => state.zoning,
    get_neighborhoodName: state => state.neighborhoodName,
    get_buildingClassDescription: state => state.buildingClassDescription,

    get_all: state => state,
  },
  mutations: {
    changeInputRangeParams(state, {name, value}) {
      Vue.set(state, name , null );
      Vue.set(state, name , value );
      Cookies.set(`${router.currentRoute.params.id}${name}` , value, { sameSite: 'strict' , expires: new Date(new Date().getTime() + 300000 ) })
    },
    vacanciesInTheLastTwentyMonths(state, value) {
      Cookies.set(`${router.currentRoute.params.id}vacanciesInTheLastTwentyMonths` , value, { sameSite: 'strict', expires: new Date(new Date().getTime() + 300000 ) })
      state['vacanciesInTheLastTwentyMonths'] = value;
    },
    ownerOccupancyInTheLastTwentyMonths(state, value) {
      Cookies.set(`${router.currentRoute.params.id}ownerOccupancyInTheLastTwentyMonths` , value, { sameSite: 'strict' ,expires: new Date(new Date().getTime() + 300000 ) })
      state['ownerOccupancyInTheLastTwentyMonths'] = value;
    },
    setGoogleCords(state, {latitude, longitude}) {
      state['latitude'] = latitude;
      state['longitude'] = longitude;
    },
    setGuid(state) {
      let guid = 'txxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
      state['guid'] = guid;
      Cookies.set(`${router.currentRoute.params.id}tx_guid` , guid, { sameSite: 'strict' ,expires: new Date(new Date().getTime() + 3000000 ) })
    },
    setAddress(state, { address, block, boroughCode, lot,currentFiscalYear, bblId, neighborhoodName, buildingClassDescription }) {
      state['currentFiscalYear'] = currentFiscalYear;
      state['address'] = address;
      state['block'] = block;
      state['boroughCode'] = boroughCode;
      state['lot'] = lot;
      state['bblId'] = bblId;
      state['neighborhoodName'] = neighborhoodName && neighborhoodName.length ?  neighborhoodName.charAt(0).toUpperCase() + neighborhoodName.slice(1).toLowerCase() : 'N/A';
      state['buildingClassDescription'] = buildingClassDescription && buildingClassDescription.length ? buildingClassDescription.charAt(0).toUpperCase() + buildingClassDescription.slice(1).toLowerCase() : 'N/A';

    },
    setScore(state, scoreResults ) {
      state['scoreResults'] = scoreResults
    },
    resSent(state ,data) {
      state['resSent'] = data
    },
    setPrices(state, {income, expenses, area, zoning }) {
      state['income'] = income || 0;
      state['expenses'] = expenses || 0;
      state['area'] = area || 0;
      state['zoning'] = zoning && zoning.length ?  zoning.charAt(0).toUpperCase() + zoning.slice(1).toLowerCase() : 'N/A';
    },
    setReason(state, {tx_action, tx_reason}) {
      state['tx_action'] = tx_action;
      state['tx_reason'] = tx_reason;
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    resetCookies (state, last_id) {
      Cookies.remove(`${last_id}vacanciesInTheLastTwentyMonths`)
      Cookies.remove(`${last_id}ownerOccupancyInTheLastTwentyMonths`)
      Cookies.remove(`${last_id}owner_area_percent`)
      Cookies.remove(`${last_id}owner_area_sf`)
      Cookies.remove(`${last_id}vacant_area`)
      Cookies.remove(`${last_id}vacanciesInTheLastTwentyMonths`)
      Cookies.remove(`${last_id}relationshipToProperty`)
      Cookies.remove(`${last_id}income_client`)
      Cookies.remove(`${last_id}area_client`)
      Cookies.remove(`${last_id}area`)
      Cookies.remove(`${last_id}income`)
      Cookies.remove(`${last_id}rented_area_sf`)
      Cookies.remove(`${last_id}expenses_client`)
      Cookies.remove(`${last_id}expenses`)
      Cookies.remove(`${last_id}zoning`)
    }
  },
  actions: {
    addToLocalStorage({getters,commit}){
      let res_data = []
      if (localStorage.getItem('resultsSent')) {
        try {
          res_data = JSON.parse(localStorage.getItem('resultsSent'));
        } catch(e) {
          localStorage.removeItem('resultsSent');
        }
      }
      res_data.push(`${getters.get_income_client}-${getters.get_expenses_client}-${getters.get_area_client}-${getters.get_vacant_area}-${getters.get_rented_area_sf}-${getters.get_owner_area_sf}`)

      const parsed = JSON.stringify(res_data);
      localStorage.setItem('resultsSent', parsed);
      commit('resSent', parsed)

    },

   async SendInfo({getters, commit}){
     if(!getters.get_guid){
       commit('setGuid')
     }
     let data_send = {
       'tx_guid' : getters.get_guid,
       'tx_bbleId' : getters.get_bblId,
       'tx_year' : getters.get_currentFiscalYear,
       'tx_income' : getters.get_income_client,
       'tx_expenses' : getters.get_expenses_client,
       'tx_area' : getters.get_area_client,
       'tx_relationshipToProperty' : getters.get_relationshipToProperty,
       'tx_vacanciesInTheLastTwelveMonths' : getters.get_vacanciesInTheLastTwentyMonths,
       'tx_vacantArea' : getters.get_vacant_area,
       'tx_ownerOccupancyInTheLastTwelveMonths' : getters.get_ownerOccupancyInTheLastTwentyMonths,
       'tx_rentedArea_sf' : getters.get_rented_area_sf,
       'tx_ownerAreaSf' : getters.get_owner_area_sf,
       'tx_address' : getters.get_address,
       'tx_block' : getters.get_block,
       'tx_boroughCode' : getters.get_boroughCode,
       'tx_lot' : getters.get_lot,
       'tx_scoreResults' : getters.scoreResults,
       'tx_action' : getters.tx_action,
       'tx_reason' : getters.tx_reason,

     }
      return await axios.post('https://mgny.force.com/sales/services/apexrest/lead/add', data_send )
      // return await axios.post('https://devmgny02-mgny.cs14.force.com/Billing/services/apexrest/lead/add', data_send )
    }
  },
  modules: {
  }
})
