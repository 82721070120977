<template>
  <div class="wrapper-taxert-resolves d-flex mx-auto">
    <div class="img-text-wrap">
      <img class="logo-block-small" src="../assets/logo_block_3.svg" alt="">
      <h2 class="taxert-resolves-title position-relative"><img class="logo-block" src="../assets/logo_block_3.svg" alt=""> resolves the mysteries<br />
        of the NYC tax assessment system <br />
        and provides clear answers to <br />
        taxpayer’s most pressing questions:
        <img class="position-absolute" src="../assets/img_line_1.png" alt="">
      </h2>
      <h2 class="taxert-resolves-title taxert-resolves-sm position-relative"><img class="logo-block" src="../assets/logo_block_3.svg" alt=""> resolves the mysteries<br />
        of the NYC tax assessment  <br />
        system and provides clear <br />
        answers to taxpayer’s <br />
        most pressing questions:
        <img class="position-absolute" src="../assets/img_line_1.png" alt="">
        <img class="taxer-line" src="../assets/img_line_1.png" alt="">
      </h2>
      <div class="d-flex justify-content-between fl-col">
        <div class="taxert-resolves-items">
          <div><img src="../assets/ill_1.png" alt=""></div>
          <h4>Is my property fairly assessed?</h4>
          <p>Most owners believe they are paying too much in real estate taxes. Without the tools and the data, they tend
            to compare their property taxes to other nearby properties.</p>
          <p>However, this method is very imprecise and leads to confusion rather than clarity.</p>
        </div>
        <div class="taxert-resolves-items">
          <div><img src="../assets/ill_2.png" alt=""></div>
          <h4>Should I protest the value?</h4>
          <p>Even after a thorough analysis reveals that the property is over-assessed, it is not always the best
            decision to protest the assessment. The formal appeal process is very time consuming, requires much
            preparation and the results are unpredictable.</p>
        </div>
      </div>
      <div class="d-flex justify-content-between fl-col">
        <div class="taxert-resolves-items mb-0">
          <div><img class="ill-shake" src="../assets/ill_3.png" alt=""></div>
          <h4>Is there a real benefit in hiring a <br />
            professional firm to appeal my taxes?</h4>
          <p>NYC property tax system is best understood by the practitioners of the Tax Certiorari field. These
            attorneys, consultants and appraisers use the time-tried approaches to contesting the valuations on an annual basis.</p>
          <p>However, owners become frustrated when the results do not match their expectations or when the fees paid
            are disproportionate to the savings in taxes.</p>
        </div>
        <div class="taxert-resolves-items mb-0">
          <div><img src="../assets/ill_4.png" alt=""></div>
          <h4>What is the likelihood of success <br />
            if I file an appeal?</h4>
          <p class="strict">The complexities of the NYC property tax system make it nearly impossible to understand what a fair
            valuation should be. It is even more difficult to quantify the likelihood of success of appeal for any given property.</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "TaxertResolves"
}
</script>

<style scoped>
.logo-block-small{
  display: none;
}
.taxert-resolves-sm{
  display: none;
}
.taxer-line{
  display: none;
}
.wrapper-taxert-resolves {
  padding-top: 240px;
  max-width: 1096px;
}
.img-text-wrap{
  width: 1096px;
}
.taxert-resolves-title {
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 64px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 84px;
  text-align: center;
  padding-bottom: 240px;
}
.taxert-resolves-title .logo-block{
  margin-top: -8px;
  width: 195px;
}
.taxert-resolves-title .position-absolute{
  height: 11px;
  width: 926.12px;
  top: 80px;
  left: 57px;
}
.taxert-resolves-items {
  width: 435px;
  margin-bottom: 112px;
}
.taxert-resolves-items > div{
  height: 100px;
}
.taxert-resolves-items img{
  width: 100px;
}
.taxert-resolves-items h4{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 33px;
  margin-bottom: 12px;
  margin-top: 24px;
}
.taxert-resolves-items p{
  color: #546C92;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 12px;
}
@media screen and (max-width: 768px) {
  .wrapper-taxert-resolves {
    padding-top: 160px;
    max-width: 720px;
  }
  .taxert-resolves-title {
    font-size: 44px;
    line-height: 58px;
    padding-bottom: 100px;
    width: 720px;
  }
  .taxert-resolves-title .logo-block{
    margin-top: -8px;
    height: 30px;
    width: 128px;
  }
  .taxert-resolves-title .position-absolute{
    height: 11px;
    width: 606.12px;
    top: 58px;
    left: 57px;
  }
  .taxert-resolves-items {
    width: 328px;
    margin-bottom: 92px;
  }
  .taxert-resolves-items h4{
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 21px;
    line-height: 33px;
    margin-bottom: 12px;
    margin-top: 24px;
  }
  .strict{margin-top: 42px}
  .ill-shake{margin-top: 32px}

}
@media screen and (max-width: 420px) {
  .wrapper-taxert-resolves {
    padding-top: 120px;
    max-width: 390px;
    justify-content: center;
  }
  .taxert-resolves-title {
    font-size: 26px;
    line-height: 34px;
    padding-bottom: 100px;
    width: 317px;
    display: none;
    margin-left: auto;
    margin-right: auto;
  }
.fl-col{
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.taxert-resolves-sm{
   display: block;
 }
  .logo-block-small{
    display: block;
    height: 24px;
    width: 102px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7px;
  }
  .logo-block{
    display: none;
  }
  .taxert-resolves-title .logo-block{
    margin-top: -8px;
    height: 30px;
    width: 128px;
  }
  .taxer-line{
    display: block;
    top: -3px;
    height: 5px;
    width: 136.12px;
    left: 87px;
    position: absolute;
  }
  .taxert-resolves-title .position-absolute{
    height: 4px;
    width: 276.12px;
    top: 33px;
    left: 22px;
  }

  .taxert-resolves-items {
    width: 316px;
    margin-bottom: 64px!important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

  }
  .taxert-resolves-items p{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .taxert-resolves-items h4{
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 8px;
    margin-top: 6px;
  }
  .taxert-resolves-items img{
    width: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .strict{margin-top: 0}
  .ill-shake{margin-bottom: -12px}

}

@media screen and (max-width: 375px) {}
@media screen and (max-width: 320px) {

  .taxer-line{
    left: 82px;
  }
  .taxert-resolves-title .position-absolute{
    left:8px
  }
  .taxert-resolves-title{
    width: 285px;
    margin-left: auto;
    margin-right: auto;
  }
  .taxert-resolves-title br{
    display: none;
  }
  .taxert-resolves-items{
    width: 320px;
    padding: 0 10px;
    margin-bottom: 56px!important;
  }
  .taxert-resolves-items h4 {
    margin-top: 4px;
  }

}
</style>
