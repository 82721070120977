import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import StepWrap from '../views/StepWrap.vue'
import TaxPurpose from '../views/TaxPurpose.vue'
import TaxPurposeSettings from '../views/TaxPurposeSettings.vue'
import RelationshipToProperty from '../views/RelationshipToProperty.vue'
import VacanciesLast12Month from '../views/VacanciesLast12Month.vue'
import PercentageVacantProperty from '../views/PercentageVacantProperty.vue'
import OwnerVsBreakdown from '../views/OwnerVsBreakdown.vue'
import OwnerOccupancy from '../views/OwnerOccupancy.vue'
import Results from '../views/Results.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/item:id',
    name: 'step',
    redirect: { name: 'tax.purpose' },
    component: StepWrap,
    children:[
      {
        path: 'tax-purpose',
        name: 'tax.purpose',
        component: TaxPurpose,
        meta: {step: 1}
      },
      {
        path: 'tax-purpose-settings',
        name: 'tax.purpose-settings',
        component: TaxPurposeSettings,
        meta: {step: 2}
      },
      {
        path: 'relationship-to-property',
        name: 'relationship.to.property',
        component: RelationshipToProperty,
        meta: {step: 3}
      },
      {
        path: 'vacancies-last-12-month',
        name: 'vacancies.last.12.month',
        component: VacanciesLast12Month,
        meta: {step: 4}
      },
      {
        path: 'percentage-vacant-property',
        name: 'percentage.vacant.property',
        component: PercentageVacantProperty,
        meta: {step: 5}
      },
      {
        path: 'owner-occupancy',
        name: 'owner-occupancy',
        component: OwnerOccupancy,
        meta: {step: 6}
      },
      {
        path: 'owner-vs-breakdown',
        name: 'owner.vs.breakdown',
        component: OwnerVsBreakdown,
        meta: {step: 7}
      },
      {
        path: 'results',
        name: 'results',
        component: Results,
        meta: {step: 8}
      },

    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
