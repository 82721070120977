<template>
  <div v-click-outside="hideAutocompleteList"  class="custom-select_wrapper">
    <div class="custom-select_input__wrapper">
      <input type="text"
             :id="id"
             :name="id"
             class="custom-select_input data-hj-allow"
             :class="{
               inputClass,
               'selected': selected !== null,
             }"
             :placeholder="placeholder"
             :value="selected"
             autocomplete="off"
             @input="inputTyping"
             @focus="handleFocus">
      <img src="../assets/icn_close.svg" alt="" v-if="isDropdownOpen" @click="hideAutocompleteList" class="custom-select_fa-icon">
      <img src="../assets/icn_open.svg" alt="" @click="handleFocus" v-else class="custom-select_fa-icon">
    </div>
    <div  class="wrapper-autocomplete-list">
      <ul v-if="Array.isArray(autocompleteList) && autocompleteList.length > 0" class="autocomplete-list">
        <li v-for="(elem, index) in autocompleteList"
            :key="index"
            @click="selectList(elem)"
            class="autocomplete-list__elem"
            :class="{'active': selected === elem}">
          {{elem}}
          <img src="../assets/icn_selected.svg" v-if="selected === elem" alt="" class="autocomplete-list_fa-icon">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: "CustomSelect",
  props: {
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    autocompleteList: [],
    selected: null,
    isDropdownOpen: false,
  }),
  methods: {
    selectList(elem) {
      this.selected = elem;
      this.isDropdownOpen = false;
      this.autocompleteList = [];
      this.$emit('selectList', {inputId: this.id, inputValue: elem});
    },
    inputTyping({ target }){
      this.selected = target.value
      this.$emit('inputTyping', {inputId: this.id, inputValue: target.value})
    },
    handleFocus(){
      this.isDropdownOpen = true;
      this.autocompleteList = this.autocomplete;
      this.$emit('handleFocus', {inputId: this.id})
    },
    hideAutocompleteList(){
      this.isDropdownOpen = false;
      this.autocompleteList = [];
    },
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.custom-select_input__wrapper {
  position: relative;
}
.custom-select_input {
  height: 48px;
  width: 220px;
  border-radius: 6px;
  background-color: $common-bg;
  border: 1px solid $common-bg;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  padding: 0 16px;
  caret-color: transparent;
  -webkit-appearance: none;
}
.custom-select_input.selected {
  color: $font-black;
}
.custom-select_input::placeholder {
  color: $font-gray;
}
.custom-select_input:focus{
  outline: none;
  border: 1px solid #104AB1;
  background: #ffffff;
}

.wrapper-autocomplete-list{
  position: relative;
}
.autocomplete-list {
  list-style: none;
  text-align: left;
  background-color: #FFFFFF;
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  z-index: 9;
  max-height: 208px;
  overflow-y: auto;
  padding: 0 16px;
  border-radius: 6px;
  border: 1px solid #D2D9E2;
}
.autocomplete-list::-webkit-scrollbar {
   width:  4px !important;
 }

.autocomplete-list::-webkit-scrollbar-thumb {
   background: #F1F3F6;
 }

.autocomplete-list::-webkit-scrollbar-track {
   background: transparent;
 }

.autocomplete-list {
   scrollbar-face-color: #F1F3F6;
   scrollbar-track-color: transparent;
 }
.autocomplete-list__elem {
  color: #000;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
  padding: 10px 0;
  border-bottom: 1px solid $white-color;
  position: relative;
}
.autocomplete-list_fa-icon {
  display: none;
}
.autocomplete-list__elem.active .autocomplete-list_fa-icon {
  display: block;
  position: absolute;
  top: 13px;
  right: 0;
  height: 14px;
  width: 16px;
  color: $font-black;
}
.autocomplete-list__elem:last-of-type {
  border-bottom: none;
}
.autocomplete-list__elem:hover {
  background-color: $common-bg;
}
.custom-select_fa-icon {
  position: absolute;
  top: 22px;
  right: 16px;
  color: #96A8BE;
  cursor: pointer
}

@media screen and (max-width: 420px) {
  .custom-select_input{
    width: 358px;
  }
}
@media screen and (max-width: 375px) {
  .custom-select_input{
    width: 343px;
  }
}
@media screen and (max-width: 320px) {
  .custom-select_input{
    width: 288px;
  }
}
</style>