<template>
  <gmap-map v-if="latitude && longitude" v-bind="options" id="map"></gmap-map>
</template>

<script >
import { gmapApi } from 'vue2-google-maps';
import { mapGetters } from 'vuex';

export default {
name: "GoogleMap",
  data: () => ({
    options: {
      zoom: 10,
      center: {
        lat: null,
        lng: null
      },
      mapTypeId: 'roadmap'
    }
  }),
  computed: {
    google: gmapApi,
    ...mapGetters({
      latitude: 'get_latitude',
      longitude: 'get_longitude',
    }),
  },
  watch: {
    latitude: {
      immediate: true,
      handler(newVal) {
        this.options.center.lat = newVal;
      }
    },
    longitude: {
      immediate: true,
      handler(newVal) {
        this.options.center.lng = newVal;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
#map {
  height: 600px;
}
</style>