<template>
  <div class="wrapper-to-find-out d-flex justify-content-center">
    <div class="wrapper-to-find-out-text position-relative" id="to_find_out">
      <h2>To find out if your property is<br />
        over-assessed, <span>enter the address<br />
        or block and lot</span></h2>
      <img class="position-absolute icn_lines" src="../assets/icn_lines.png" alt="">
      <img class="position-absolute icn_arrow" src="../assets/icn_arrow.png" alt="">
      <div class="d-flex justify-content-center">
        <div class="text-center">
          <SearchComponent :order="'page bottom search'"
                           class="no-shadow"></SearchComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchComponent from '../components/SearchComponent.vue';

export default {
  name: "ToFindOut",
  components: {
    SearchComponent,
  },
}
</script>

<style scoped>
icn_lines .lines-sm{
  display: none;
}
.wrapper-to-find-out {
  margin-top: 281px;
  background-color: #F9FAFF;
  padding-top: 120px;
  padding-bottom: 120px;
}
.wrapper-to-find-out-text h2{
  text-align: center;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 64px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 72px;
  margin-bottom: 64px;
}
.wrapper-to-find-out-text h2 span{
  color: #104AB1;
}
.icn_lines {
  top: 164px;
  left: 20px;
  height: 83px;
  width: 210px;
}
.icn_arrow {
  top: 188px;
  right: -150px;
  height: 240px;
  width: 322px;
}
@media screen and (max-width: 768px) {
  .wrapper-to-find-out {
    margin-top: 120px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .wrapper-to-find-out-text h2{
    font-size: 44px;
    font-weight: bold;
    line-height: 58px;
    margin-bottom: 64px;
  }
  .icn_lines{
    display: none;
  }
  .icn_arrow{
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .no-shadow{
    box-shadow: none;
  }
  .wrapper-to-find-out {
    margin-top: 10px;
    padding-top: 64px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .wrapper-to-find-out-text h2{
    width: 340px;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 64px;
    margin-right: auto;
    margin-left: auto;
  }
  .wrapper-to-find-out-text h2 br {
    display: none;
  }
  .icn_lines{
    display: block;
    top: 112px;
    left: 18px;
    height: 33px;
    width: 67px;
  }
  .icn_arrow{
    display: block;
    top: 93px;
    left: 301px;
    height: 65px;
    width: 87.21px;
    transform: rotate(-24deg);
  }
}
@media screen and (max-width: 375px) {
  .wrapper-to-find-out {
    width: 375px;
  }
  .icn_lines {
    width: 67px;
  }
  .icn_lines {
    width: 67px;
  }
  .icn_arrow {
    left: 288px;
  }
}
@media screen and (max-width: 320px) {
  .wrapper-to-find-out {
    width: 320px;
  }
  .wrapper-to-find-out-text h2{
    width: 230px;
  }
  .icn_lines {
    top: 168px;
  }
  .icn_arrow{
    display: block;
    top: 159px;
    left: 245px;
    height: 49px;
    width: 65.74px;
    transform: rotate(-24deg);
  }
}
</style>
