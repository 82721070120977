<template>
  <input type="text" :name="id" :id="id" class="input-default data-hj-allow" :class="inputClass" :placeholder="placeholder"
         autocomplete="off" @input="inputTyping">
</template>

<script>
export default {
  name: "InputDefault",
  props: {
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    inputTyping({ target }){
      this.selected = target.value
      this.$emit('inputTyping', {inputId: this.id, inputValue: target.value})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.input-default {
  height: 48px;
  width: 178px;
  border-radius: 6px;
  background-color: $common-bg;
  color: $font-black;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  border: 1px solid $common-bg;
  margin-left: 16px;
  padding: 0 16px;
  -webkit-appearance: none;
}
.input-default::placeholder {
  color: $font-gray;
}
.input-default:focus{
  outline: none;
  background-color: #FFFFFF;
  border: 1px solid #104AB1;
}
@media screen and (max-width: 420px) {
  .input-default{
    width: 171px;
  }
}
@media screen and (max-width: 375px) {
  .input-default{
    width: 164px;
  }
}
@media screen and (max-width: 320px) {
  .input-default{
    width: 136px;
  }
}
</style>