<template>
  <div class="wrapper-superb-analytics d-flex justify-content-center">
    <div class="d-flex tax-col">
      <div class="wrapper-superb-analytics-text">
        <h2><span>Superb </span><br />Analytics</h2>
        <p>Taxert researches millions of data points and uses advanced machine learning techniques to provide assessment estimates and to guide
          taxpayers through the appeal process.</p>
      </div>
      <img src="../assets/ill_block_4.png" alt="" class="ill_block">
    </div>
  </div>
</template>

<script>
export default {
  name: "NewYorkCityPropertyTaxes"
}
</script>

<style scoped>
.wrapper-superb-analytics {
  padding-top: 282px;
}
.ill_block {
  height: 510px;
  width: 520px;
  opacity: 0.95;
}
.wrapper-superb-analytics-text {
  padding-top: 115px;
  max-width: 534px;
  margin-right: 82px;
}
.wrapper-superb-analytics-text h2{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 64px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 80px;
  margin-bottom: 32px;
}
.wrapper-superb-analytics-text h2 span{
  color: #104AB1;
}
.wrapper-superb-analytics-text p{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
}
@media screen and (max-width: 768px) {
  .wrapper-superb-analytics {
    padding-top: 160px;
  }
  .ill_block {
    height: 330px;
    width: 348px;
    opacity: 0.9;
  }
  .wrapper-superb-analytics-text {
    padding-top: 45px;
    max-width: 332px;
    margin-right: 40px;
  }
  .wrapper-superb-analytics-text h2{
    font-size: 44px;
    line-height: 56px;
    margin-bottom: 32px;
  }
  .wrapper-superb-analytics-text p{
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 26px;
  }
}
@media screen and (max-width: 420px) {
  .wrapper-superb-analytics {
    padding-top: 56px;
  }
  .tax-col{
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .ill_block {
    height: 320px;
    width: 320px;
    opacity: 0.9;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
  }
  .wrapper-superb-analytics-text {
    padding-top: 24px;
    max-width: 332px;
    margin-right: auto;
    margin-left: auto;

  }
  .wrapper-superb-analytics-text h2{
    margin-bottom: 12px;
    width: 301px;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 42px;
  }
  .wrapper-superb-analytics-text h2 br {
    display: none;
  }
  .wrapper-superb-analytics-text p{
    width: 310px;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
  }
}
@media screen and (max-width: 375px) {
  .wrapper-superb-analytics-text h2{
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 40px;
  }
  .wrapper-superb-analytics {
   text-align: center;
  }
}
@media screen and (max-width: 320px) {

}

</style>
