<template>
  <div class="wrapper-tax-purpose mx-auto">
    <h4 class="tax-purpose-title">What percentage of the property remained vacant for the last 12 months?</h4>
    <h5 class="tax-purpose-hint">This may be an approximate value.</h5>

    <div class="tax-purpose-wrap">
      <div class="tax-purpose-item">
        <div>
          <img src="../assets/img_vacancy.png" alt="" class="tax-purpose-image">
          <p class="tax-purpose-text">Vacancy</p>
          <p v-if="area_client" class="tax-purpose-subtext">Total sf: {{ area_client.toLocaleString() }}</p>
        </div>
        <div class="tax-purpose-result">
          <p class="tax-purpose-percent">{{ `${vacant_area_percent}%` }}</p>
          <p v-if="vacant_area !== null" class="tax-purpose-sum"><span class="or-condition">or sf: </span>{{ `${vacant_area.toLocaleString()}` }}</p>
        </div>
      </div>
      <CustomInputRange id="area" @slider_change="slider_change" @slider_input="slider_input"  :min_value="0" :max_value="area_client" :selected_value="vacant_area"></CustomInputRange>
    </div>
      <ButtonDefault @click="handleClickResult()" text="Done!" />
  </div>
</template>

<script>
import ButtonDefault from "@/components/ButtonDefault";
import CustomInputRange from "@/components/CustomInputRange";
import {mapGetters} from "vuex";
export default {
  name: "PercentageVacantProperty",
  components: {
    ButtonDefault,
    CustomInputRange
  },
  computed: {
    ...mapGetters({
      area_client: 'get_area_client',
      vacant_area_store: 'get_vacant_area',
    }),
  },
  watch: {
    area_client: {
      immediate: true,
      handler(newVal){
        if(newVal ) {
          this.vacant_area = newVal / 2;
        }
      }
    },
    vacant_area_store: {
      immediate: true,
      handler(newVal){
        if(newVal) {
          this.vacant_area = newVal;
        }
      }
    }
  },
  beforeDestroy() {
    this.change_count = 0
  },
  data: () => ({
    vacant_area: null,
    vacant_area_percent: 50,
    change_count : 0
  }),
  methods:{
      async handleClickResult(){
        this.track()
        this.$store.dispatch('SendInfo')
        const res = {}
        res.status = 'ok'
        if(res.status === 'ok'){
          this.$router.push({name :'owner-occupancy'})
        }
      },
    slider_change(){
      this.change_count++
    },
    slider_input({inputValue }){
      this.vacant_area = Number(inputValue);
      this.$store.commit('changeInputRangeParams', {name: 'vacant_area', value: Number(inputValue)})
      this.vacant_area_percent = Math.floor(this.vacant_area * 100 / this.area_client);
    },
    track(){
      this.$gtag.event('Slider change', {
        'event_category': "percentage-vacant-property",
        'value': this.change_count - 1  // because initial causes update
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.wrapper-input-range {
  margin-top: 5px;
}
.wrapper-tax-purpose {
  height: 312px;
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26,41,60,0.1);
  padding: 24px;
}
.tax-purpose-title {
  height: 33px;
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  width: 100%;
  margin-bottom: 6px;
}
.tax-purpose-hint{
  height: 19px;
  width: 100%;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 24px;
}

.btn-default {
  width: 264px;
  margin: 24px auto 0;
  display: block;
}
.tax-purpose-wrap{
  padding: 19px 20px;
  height: 120px;
  width: 824px;
  border-radius: 10px;
  background-color: $common-bg;
  margin-bottom: 8px;
  border: 1px solid $common-bg;
}
.tax-purpose-wrap:hover {
  border: 1px solid rgba(11,40,78,0.2);
  border-radius: 10px;
  background-color: transparent;
}
.tax-purpose-wrap:hover .tax-purpose-sum{
  color: #082E60;
}
.tax-purpose-wrap:hover .tax-purpose-percent{
  color: #082E60;
}
.tax-purpose-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.tax-purpose-image,
.tax-purpose-text {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}
.tax-purpose-image{
  height: 40px;
  float: left;
  width: 35px;
}

.tax-purpose-text {
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 3px;
  margin-left: 56px;
}
.tax-purpose-subtext {
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0;
  margin-left: 58px;
}
.tax-purpose-percent {
  text-align: right;
  color: $font-blue;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 6px;
}
.tax-purpose-sum {
  text-align: right;
  color: $font-blue;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0;
}
.tax-purpose-sum .or-condition{
  color: $font-gray;
  font-weight: normal;
}
.tax-purpose-result {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .tax-purpose-title {
    height: 60px;
    line-height: 30px;
  }
  .wrapper-tax-purpose {
    height: 327px;
    width: 720px;
    padding: 20px;
  }
  .tax-purpose-hint{
    margin-bottom: 20px;
  }
  .btn-default{
    margin-top: 20px;
  }
  .tax-purpose-wrap{
    width: 680px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 420px) {
  .wrapper-tax-purpose {
    height: 315px;
    width: 390px;
    padding: 16px;
    border-radius: 0;
  }
  .tax-purpose-title {
    height: 56px;
    width: 358px;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
  }
  .tax-purpose-hint{
    height: 17px;
    width: 196px;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .tax-purpose-wrap{
    width: 358px;
    margin-bottom: 0;
    padding: 16px 12px;
  }
  .btn-default{
    width: 358px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 375px) {
  .wrapper-tax-purpose {
    height: 320px;
    width: 375px;
  }
  .tax-purpose-title {
    height: 56px;
    width: 350px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .tax-purpose-wrap{
    width: 343px;
  }
  .btn-default{
    width: 343px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 320px) {
  .wrapper-tax-purpose {
    height: 332px;
    width: 320px;
  }
  .tax-purpose-title {
    height: 70px;
    width: 298px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .tax-purpose-wrap{
    width: 288px;
  }
  .btn-default{
    width: 288px;
    margin-bottom: 8px;
  }

}
</style>