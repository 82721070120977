<template>
  <div v-click-outside="hideAutocompleteList"  class="wrapper-autocomplete">
    <div class="wrapper-input">
      <input type="text"
             :id="id"
             :name="id"
             class="input-autocomplete-default data-hj-allow"
             :class="inputClass"
             :placeholder="placeholder"
             :value="selected"
             autocomplete="off"
             @input="inputTyping"
             @focus="handleFocus">
<!--      <font-awesome-icon v-if="clearInput" class="input-autocomplete_fa-icon" icon="times" @click="clearInputClick" />-->
      <img v-if="selected" src="../assets/icn_delete.svg" alt="" class="input-autocomplete_fa-icon" @click="clearInputClick">
    </div>
    <div  class="wrapper-autocomplete-list">
      <simplebar v-if="Array.isArray(autocompleteList) && autocompleteList.length > 0" data-simplebar-auto-hide="false">
        <ul  class="autocomplete-list">
          <li v-for="(elem, index) in autocompleteList"
              :key="index"
              @click="selectList(elem)"
              class="autocomplete-list__elem">
            <span>{{ replaceSelectedElem(elem) }}</span>{{ replaceOtherElem(elem) }}
          </li>
        </ul>
      </simplebar>
      <div v-if="empty_search && selected" class="not-found">
        We couldn't find this address
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import simplebar from 'simplebar-vue';

export default {
  name: "InputAutocomplete",
  props: {
    id: {
      type: String,
      required: true
    },
    empty_search: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },

    autocomplete: {
      type: Array,
      required: true,
    }
  },
  components: {
    simplebar
  },
  watch: {
    autocomplete: {
      immediate: true,
      handler(newVal){
        this.autocompleteList = newVal;
      }
    }
  },
  data: () => ({
    autocompleteList: [],
    selected: null,
    clearInput: false
  }),
  methods: {
    selectList(elem) {
      this.selected = elem;
      this.autocompleteList = [];
      this.$emit('selectList', {inputId: this.id, inputValue: elem});
    },
    inputTyping({ target }){
      this.selected = target.value
      if(this.selected.length > 3) {
        this.clearInput = true;
      }
      this.$emit('inputTyping', {inputId: this.id, inputValue: target.value})
    },
    handleFocus(){
      this.$emit('handleFocus', {inputId: this.id})
    },
    hideAutocompleteList(){
      this.isDropdownOpen = false;
      this.autocompleteList = [];
    },
    clearInputClick(){
      this.selected = null;
      this.clearInput = false;
      this.autocompleteList = [];
    },
    replaceSelectedElem(elem) {
      return elem.slice(0, this.selected.length);
    },
    replaceOtherElem(elem) {
      return elem.slice(this.selected.length)
    }
  },
  directives: {
    ClickOutside
  }
}
</script>


<style lang="scss" >
@import "../scss/variables.scss";
.simplebar-track.simplebar-vertical {
  top: 0;
  width: 8px !important;
}
.not-found{
  height: 120px;
  width: 548px;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(26, 41, 60, 0.15);
  position: absolute;
  z-index: 99999;
  top: 4px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1 !important;
  transition: opacity 0s linear;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: #F1F3F6 !important;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity .2s linear;
}
</style>

<style lang="scss" scoped>
@import "../scss/variables.scss";
[data-simplebar] {
  list-style: none;
  text-align: left;
  background-color: #FFFFFF;
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  z-index: 9;
  max-height: 232px;
  padding: 0;
  border-radius: 6px;
  border: 1px solid #D2D9E2;
}
.wrapper-input {
  position: relative;
}
.input-autocomplete-default {
  height: 44px;
  width: 100%;
  border-radius: 6px;
  background-color: $common-bg;
  border: 1px solid $common-bg;
  color: #041429;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  padding: 0 16px;
  -webkit-appearance: none;
}
.input-autocomplete-default::placeholder {
  color: $font-gray;
}
.input-autocomplete-default:focus{
  outline: none;
  background-color: #FFFFFF;
  border: 1px solid #104AB1;
}
.wrapper-autocomplete-list{
  position: relative;
}

.autocomplete-list {
  list-style: none;
  padding: 0 ;
  margin: 0;
}
.autocomplete-list__elem {
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
  padding: 10px 16px;
  position: relative;
  cursor:pointer
}
.autocomplete-list__elem:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 16px;
  width: 514px;
  border-bottom: 1px solid #D2D9E2;
}
.autocomplete-list__elem span{
  color: $font-black;
}
.autocomplete-list__elem:last-of-type {
  border-bottom: none;
}
.autocomplete-list__elem:hover {
  background-color: $common-bg;
}
.input-autocomplete_fa-icon {
  position: absolute;
  top: 17px;
  right: 19px;
  color: #96A8BE;
}
.input-autocomplete_fa-icon:hover {
  cursor: pointer;
}


  @media screen and (max-width: 420px) {
    .not-found{
      width: 100%;
    }
  }
</style>