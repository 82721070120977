<template>
    <button  v-b-modal.calc-modal class="help-btn" :style="{width: width}" >{{text}}</button>
</template>

<script>
export default {
name: "helpButton",
  props:{
    text:{
      type: String
    },
    width:{
      type: String,
      default: 'auto'
    }
  }
}
</script>

<style lang="scss" >
.help-btn{
  height: 48px;
  border-radius: 6px;
  border-color: transparent;
  background-color: #104AB1;
  padding: 13px 27px ;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  &:hover {
    background-color: #273194;
    border-color: transparent;
  }
  &:active {
    background-color: #717CE6;
    border-color: transparent;
  }
  &:focus {
    outline: transparent;

  }
  }
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .help-btn{
    height: 44px;
    line-height: 20px;
    padding: 10px 27px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .help-btn{
    width: 100%!important;
    height: 40px;
    line-height: 15px;
    padding: 10px 27px;
  }
  @media screen and (min-width: 320px) and (max-width: 374px) {
    .help-btn{
      width: 100%!important;
    }
  }
}
</style>
