<template>
  <div>
    <div class="wrap-top-blocks flex-container ">
      <div class="block-rect assess-level ">
        <div>
          <h3 class="assess-level-title" :style="getAsessColor(asses_level)">{{asses_level}}</h3>
          <h4 class="assess-level-subtitle">Assessed level</h4>
          <div class="speedometer-wrapper">
            <img src="../assets/speedometer.svg" class="schkala" alt="">
            <img src="../assets/speedometer_line.svg" alt="" class="speedometer-arrow" :class="`speedometer-arrow-${overall_score_grade}`">
          </div>
          <div class="assess-level-text">
            <div>
              <p class="severely-underassessed">Severely Underassessed</p>
              <p class="moderately-underassessed">Moderately Underassessed</p>
            </div>
            <div class="margin-left-38">
              <p class="fairly-assessed">Fairly Assessed</p>
              <p class="moderately-overassessed">Moderately Overassessed</p>
              <p class="severely-overassessed">Severely Overassessed</p>
            </div>
          </div>
        </div>
      </div>
      <div class="block-rect comparison ">
        <div class="">
          <h3 class="comparison-level-title">NOI comparison with city assumption</h3>
          <h4 class="comparison-level-subtitle">Net operating income, before taxes</h4>
          <div class="comparison-details">
            <div class="comparison-details-perceived d-flex justify-content-between"
                 :class="getPerceived() > 0 ? 'perc-fll' : 'perc-brdr'"
                 :style="`width: ${perceived_width}%`">
              <p class="p-descr">Perceived: </p>
              <span v-if="is_loading">...</span>
              <span v-else-if="getPerceived()">${{ getPerceived()}}/sf</span>
              <span v-else>N/A</span>
            </div>
            <div class="comparison-details-actual d-flex justify-content-between" :class="getActual() > 0 ? 'act-fll' : 'act-brdr'" :style="`width: ${actual_width}%`">
              <p class="p-descr">Actual: </p>
              <span v-if="is_loading">...</span>
              <span v-else-if="getActual()">${{getActual()}}/sf</span>
              <span v-else>N/A</span>
            </div>
          </div>

          <div class=" wrap-comparison-level-percent d-inline-block">
            <div class="row">
              <div class="col-lg-3 col-4" >
                <div class="rectangle-square d-flex align-items-start flex-column" :class="noi_level !== 'higher' && noi_level !== 'lower' ? 'white-rect' :''"
                     :style="`background-color: #${noi_level === 'higher' ? 'CDD1FF' : noi_level === 'lower' ? 'FFB57B' : 'FFFFFF'};`">
                    <div class="line-arrow" >
                      <img v-if="noi_level === 'higher'"  src="../assets/icn_higher.svg"  alt="">
                      <img v-if="noi_level === 'lower'" src="../assets/icn_lower.svg"  alt="">
                    </div>
                    <div >
                      <p v-if="is_loading" class="comparison-level-percent">...</p>
                      <p v-else-if="getActual() && getPerceived()"
                         :style="`font-size: ${noi_calculated && noi_calculated.toString().length>2 ? '30px' : '32px'};`"
                         class="comparison-level-percent">{{getNoiCalculated()}}%</p>
                      <p v-else class="comparison-level-percent">N/A<a href=""></a></p>
                    </div>
                </div>
              </div>
              <div class="col-lg-9 col-8">
                <p v-if="noi_level" class="comparison-level-percent-description text-center">Your perceived NOI is {{ noi_level }} <span>{{noi_level =='equal' ? 'to' : 'than'}}</span> your actual.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between wrap-low-blocks">
      <div class="">
        <div class="block-rect basic-and-recomm">
          <h3 class="basic-and-recomm-title ">Tax / SF comparison graph</h3>
          <div class="basic-and-recomm-props-wrap ">
            <div class="comparison-details-perceived d-flex justify-content-between"
                 :class="tax_liability_per_sf > 0 ? 'perc-fll' : 'perc-brdr'"
                 :style="`width: ${tax_liability_per_sf_width}%`">
              <p class="p-descr" title="Projected Tax Liability/sf">Projected Tax Liability/sf: </p>
              <span v-if="is_loading">...</span>
              <span v-else>${{ tax_liability_per_sf}}/sf</span>
            </div>
            <div class="comparison-details-actual d-flex justify-content-between"
                 :class="average_comp_tax_sf > 0 ? 'act-fll' : 'act-brdr'"
                 :style="`width: ${average_comp_tax_sf_width}%`">
              <p class="p-descr" title="Average Comparable Tax/sf">Average Comparable Tax/sf: </p>
              <span v-if="is_loading">...</span>
              <span v-else>${{average_comp_tax_sf}}/sf</span>
            </div>
          </div>
        </div>
      </div>
      <div class="rec-wrap">
        <div class="block-rect basic-and-recomm">
          <h3 class="basic-and-recomm-title">Recommendation</h3>
          <div class=" recommendation-block" :class="recommendation === 'Appeal' ? 'success' : recommendation === 'Do not appeal'? 'reject' : ''">
           <div class="row">
             <div class="col-3">
               <div class="rectangle-square d-flex" :style="recommendation === 'Appeal' ? 'background-color : #A7DC59' :'background-color :#FFB57B'">
                 <div v-if="recommendation  === 'Appeal'" class="icon-recommend align-self-center">
                   <img class="recomm-emoji" src="../assets/appeal.png"  alt="">
                 </div>
                 <div v-if="recommendation  === 'Do not appeal'" class="icon-recommend align-self-center">
                    <img class="recomm-emoji" src="../assets/stop.png"  alt="">
                 </div>
               </div>
             </div>
             <div class="col-9 text-center">
               <p class="flex-fill recommendation-block-text">{{ recommendation }}</p>
             </div>
           </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="block-rect sign-up">-->
<!--      <h2 class="sign-up-title">Sign Up</h2>-->
<!--      <h3 class="sign-up-subtitle">Sign up and take control of your property taxes with us!</h3>-->
<!--      <div class="sign-up-form">-->
<!--        <div class="sign-up-socialite flex-container">-->
<!--          <button class="fb-logo socialite-btn">via Facebook</button>-->
<!--          <button class="gm-logo socialite-btn">via Gmail</button>-->
<!--        </div>-->
<!--        <div class="wrapper-or-block">-->
<!--          <span class="or-block">Or create an account</span>-->
<!--        </div>-->
<!--        <form action="">-->
<!--          <div class="flex-container">-->
<!--            <input type="text" class="sign-up-first-name" placeholder="First name">-->
<!--            <input type="text" class="sign-up-last-name" placeholder="Last name">-->
<!--          </div>-->
<!--          <input type="text" class="sign-up-email" placeholder="Email">-->
<!--          <ButtonDefault text="Sign Up!"/>-->
<!--        </form>-->
<!--      </div>-->
<!--    </div>-->
    <div class=""><FullReport></FullReport></div>
  </div>
</template>

<script>
import FullReport from "@/views/results/FullReport";
import {mapGetters} from "vuex";
import axios from "axios";
export default {
name: "Results",
  components: {
    FullReport,
  },
  data: () => ({
    asses_level: 'Calculating..',
    is_loading: true,
    noi_calculated: null,
    comparison_level_percent: 33,
    overall_score_grade : 0,
    recommendation : 'Appeal',
    noi_level: '',
    perceived_width: 100,
    actual_width: 100,
    average_comp_tax_sf: 0,
    average_comp_tax_sf_width: 100,
    tax_liability_per_sf:0,
    tax_liability_per_sf_width:100

  }),
  computed: {
    ...mapGetters({
      get_all: 'get_all',
      zoning: 'get_zoning',
      neighborhoodName: 'get_neighborhoodName',
      buildingClassDescription: 'get_buildingClassDescription',
      bblId: 'get_bblId',
      currentFiscalYear: 'get_currentFiscalYear',
      income: 'get_income',
      income_client: 'get_income_client',
      expenses: 'get_expenses',
      expenses_client: 'get_expenses_client',
      area_client: 'get_area_client',
      area: 'get_area',
      relationshipToProperty: 'get_relationshipToProperty',
      vacanciesInTheLastTwentyMonths: 'get_vacanciesInTheLastTwentyMonths',
      vacant_area: 'get_vacant_area',
      ownerOccupancyInTheLastTwentyMonths: 'get_ownerOccupancyInTheLastTwentyMonths',
      rented_area_sf: 'get_rented_area_sf',
      owner_area_sf: 'get_owner_area_sf',

    })
  },
  watch: {
    bblId: {
      handler: function() {
        this.getData()
      },
      immediate: true
    }
  },
  created(){
    console.log('created resultz.vue' , Date.now())
  },
  methods: {
    getAsessColor(name) {
      switch (name) {
        case 'Severely Underassessed' :
          return 'color: #80B926'
        case 'Moderately Underassessed' :
          return 'color: #A7DC59'
        case 'Fairly assessed' :
          return 'color: #FFE086'
        case 'Moderately Overassessed' :
          return 'color :#FFB57B'
        case 'Severely Overassessed' :
          return 'color :#FF7564'
      }
    },
    getNoiCalculated() {
      let calc
      if (this.getPerceived() < this.getActual()) {
        calc = (this.getPerceived() / this.getActual() - 1)
        this.noi_level = 'lower'
      }
      if (this.getPerceived() > this.getActual()) {
        calc = (this.getPerceived() / this.getActual() - 1)
        this.noi_level = 'higher'
      }
      if (this.getPerceived() == this.getActual()) {
        calc = 0
        this.noi_level = 'equal'
      }
      if (calc > 0) {
        this.actual_width = this.getPerceived() < this.getActual() ? 100 : (this.getActual() / this.getPerceived()) * 100
        this.perceived_width = this.getPerceived() < this.getActual() ? (this.getActual() / this.getPerceived()) * 100 : 100
      } else {
        this.actual_width = this.getPerceived() < this.getActual() ? 100 : (this.getPerceived() / this.getActual()) * 100
        this.perceived_width = this.getPerceived() < this.getActual() ? (this.getPerceived() / this.getActual()) * 100 : 100
      }
      this.noi_calculated = Math.round(Math.abs(calc * 100))
      return this.noi_calculated
    },
    getPerceived() {
      let area_calc = this.area ? this.area : this.area_client
      let income_calc = this.income ? this.income : this.income_client
      let expenses_calc = this.expenses ? this.expenses : this.expenses_client
      return (area_calc && income_calc && expenses_calc) ? Math.round(((income_calc - expenses_calc) / area_calc) * 100) / 100 : null
    },
    getActual() {
      return (this.area_client && this.income_client && this.expenses_client) ? Math.round(((this.income_client - this.expenses_client) / this.area_client) * 100) / 100 : null
    },
    calculateProjected(data){
      this.average_comp_tax_sf = (data.average_comp_tax_sf && typeof data.average_comp_tax_sf === "number") ? (Math.round(data.average_comp_tax_sf* 100)/100) : 0
      this.tax_liability_per_sf = (data.tax_liability_per_sf && typeof data.tax_liability_per_sf === "number") ? Math.round(data.tax_liability_per_sf* 100)/100  :  0
      if ( this.average_comp_tax_sf < this.tax_liability_per_sf){
        this.tax_liability_per_sf_width = 100;
        this.average_comp_tax_sf_width = (this.average_comp_tax_sf /this.tax_liability_per_sf) * 100
      }
      else{
        this.average_comp_tax_sf_width = 100;
        this.tax_liability_per_sf_width = (this.tax_liability_per_sf / this.average_comp_tax_sf) * 100
      }
    },
    async getData() {
      if (!this.bblId) {
        return false
      }
      console.log('resultz.vue getData' , Date.now())
      this.is_loading = true;
      let params = {
        'bbleId': this.bblId,
        'year': this.currentFiscalYear,
        'income': this.income_client,
        'expenses': this.expenses_client,
        'area': this.area,
        'relationshipToProperty': this.relationshipToProperty,
        'vacanciesInTheLastTwentyMonths': this.vacanciesInTheLastTwentyMonths,
        'vacantArea': this.vacant_area,
        'ownerOccupancyInTheLastTwentyMonths': this.ownerOccupancyInTheLastTwentyMonths,
        'rentedArea_sf': this.rented_area_sf,
        'ownerAreaSf': this.owner_area_sf,
      }

      let url = `https://old.portal.mgnyconsulting.com:8091/API/PublicPortal/getTaxertScore`
      const {data} = await axios.post(url, params);
      console.log('resultz.vue then resp' , Date.now())
      this.calculateProjected(data)
      this.is_loading = false;
      this.asses_level = data.assessed_level;
      this.comparison_level_percent = data.av_change_percent;

      switch (true) {
        case (this.income_client > 999999)  :
          this.recommendation = "Appeal";
          break;
        case (data.overall_score > -5 && data.overall_score <= -1)  :
          this.recommendation = "Do not appeal";
          break;
        case data.overall_score >= 0 || data.overall_score < 15 :
          this.recommendation = "Appeal";
          break;
      }
      switch (true) {
        case data.overall_score <= -5 :
          this.overall_score_grade = 1;
          break;
        case data.overall_score === -4 || data.overall_score === -3 :
          this.overall_score_grade = 2;
          break;
        case data.overall_score === -2 || data.overall_score === -1 :
          this.overall_score_grade = 3;
          break;
        case data.overall_score === 0 :
          this.overall_score_grade = 4;
          break;
        case data.overall_score === 1 || data.overall_score === 2 || data.overall_score === 3 :
          this.overall_score_grade = 5;
          break;
        case data.overall_score === 4 || data.overall_score === 5 || data.overall_score === 6:
          this.overall_score_grade = 6;
          break;
        case data.overall_score === 7 || data.overall_score === 8  :
          this.overall_score_grade = 7;
          break;
        case data.overall_score === 9 || data.overall_score === 10  :
          this.overall_score_grade = 8;
          break;
        case data.overall_score === 11 || data.overall_score === 12 || data.overall_score === 13  :
          this.overall_score_grade = 9;
          break;
        case data.overall_score === 14  :
          this.overall_score_grade = 9;
          break;
        case data.overall_score > 14  :
          this.overall_score_grade = 10;
          break;
      }

      this.$store.commit('setScore',
          {
            assesLevel: this.asses_level,
            overallScore: data.overall_score,
            overallScoreGrade: this.overall_score_grade,
            recommendation: this.recommendation,
            comparisonLevelPercent: this.comparison_level_percent,
            actual : this.getActual(),
            perceived : this.getPerceived(),
            noi: this.getNoiCalculated(),
            noiLevel : this.noi_level
          })
      this.$store.dispatch('SendInfo')
    },


  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.rectangle-square{
  height: 91px;
  width: 91px;
  border-radius: 10px;
  padding: 10px;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
}
.white-rect{
  border: 1px solid #E6E8FF;
}
.line-arrow{
  line-height: 16px;
  margin-bottom: auto;
  fill:$font-black;
}

.recomm-emoji{
  width: 38px;
}
.icon-recommend{
  margin: 19px;
}
.wrap-comparison-level-percent {
  height: 115px;
  width: 376px;
  border-radius: 8px;
  background-color: rgba(171, 200, 239, .1);
  padding: 12px;
  margin-top: 12px;
}
.speedometer-wrapper {
  border-bottom: 1px solid rgba(150, 168, 190, .2);
  margin-bottom: 11px;
  padding-bottom: 11px;
  text-align: center;
  position: relative;
}
.speedometer-arrow {
  position: absolute;
  bottom: 2px;
  left: 105px;
  -webkit-transform-origin: 85% 85%;
  -moz-transform-origin: 85% 85%;
  -o-transform-origin: 85% 85%;
  -ms-transform-origin: 885% 85%;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;

}
.speedometer-arrow-0 {
  transform: rotate(-46deg);
}
.speedometer-arrow-1 {
  transform: rotate(-35deg);
}
.speedometer-arrow-2 {
  transform: rotate(-23deg);
}
.speedometer-arrow-3 {
  transform: rotate(2deg);
}
.speedometer-arrow-4 {
  transform: rotate(14deg);
}
.speedometer-arrow-5 {
  transform: rotate(39deg);
}
.speedometer-arrow-6 {
  transform: rotate(52deg);
}
.speedometer-arrow-7 {
  transform: rotate(77deg);
}
.speedometer-arrow-8 {
  transform: rotate(89deg);
}
.speedometer-arrow-9 {
  transform: rotate(113deg);
}
.speedometer-arrow-10 {
  transform: rotate(125deg);
}
.wrap-top-blocks {
  margin-top: 0;
}
.fb-logo{
  background: url('../assets/facebook_logo.svg') no-repeat center ;
}
.gm-logo {
  background: url('../assets/google_logo.svg') no-repeat center ;
}
.fb-logo, .gm-logo {
  background-position-x: 9px;
  background-position-y: 5px;
}
.arrow-up{
  height: 12px;
  width: 20px;
  margin-left: 8px;
}
.block-rect{
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26,41,60,0.1);
  margin-bottom: 24px;
  padding: 24px;
}
.basic-and-recomm{
  height: 214px;
}
.assess-level{
  height: 370px;
  width: 424px;
}
.assess-level-title{
  color: #8ECC31;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.35px;
  line-height: 27px;
  margin-bottom: 2px;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  text-align: center;
}
.assess-level-subtitle{
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 19px;
  text-align: center;
}
.assess-level-text {
  color: $font-black;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 22px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
.assess-level-text p{
  margin-bottom: 6px;
  padding-left: 20px;
  position: relative;
}
.assess-level-text p:before{
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #082E60;
}
.assess-level-text p.severely-underassessed:before{
  background: #80B926;
}
.assess-level-text p.moderately-underassessed:before{
  background: #A7DC59;
}
.assess-level-text p.fairly-assessed:before{
  background: #FFE086;
}
.assess-level-text p.moderately-overassessed:before{
  background: #FFB57B;
}
.assess-level-text p.severely-overassessed:before{
  background: #FF7564;
}

.comparison {
  height: 370px;
  width: 424px;
  margin-left: 24px;
}
.comparison-level-title{
  color: $font-black;
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 27px;
  margin-bottom: 2px;
  text-align: center;
}
.comparison-level-subtitle{
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 24px;
  text-align: center;
}
.comparison-level-percent{
  color: $font-black;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.56px;
  font-family: "Open Sans";
  line-height: 27px;
  position: relative;
  margin-bottom: 0;
}
.arrow-img{
  content: '';
  position: absolute;
  top: 0;
  right: -30px;
  display: block;
  width: 20px;
  height: 12px;
}

.comparison-level-percent-description{
  height: 44px;
  width:  185px;
  color: $font-black;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 24px auto;
}

.comparison-details {
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  background-color: rgba(171, 200, 239, .1);

}
.comparison-details-perceived {
  height: 44px;
  border-radius: 8px;
  color: #0A1250;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 11px 16px;
  margin-bottom: 8px;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  min-width: 72px;
}
.comparison-details-perceived.perc-brdr{
  border: 1px solid #CDD1FF;
}
.comparison-details-perceived.perc-fll{
  background-color: #CDD1FF;
}

.comparison-details-perceived span {
  font-weight: 600;
  color: $font-black;
  margin-left: 5px;
}


.comparison-details-actual {
  height: 44px;
  border-radius: 8px;
  min-width: 72px;
  color: #0A1250;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 11px 16px;
  margin-bottom: 0;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
}
.comparison-details-actual.act-brdr{
  border: 1px solid #FFB57B;
}
.comparison-details-actual.act-fll{
  background-color: #FFB57B;
}

.comparison-details-actual span {
  font-weight: 600;
  color: $font-black;
  margin-left: 5px;
}
.basic-and-recomm-props-wrap {
  width: 376px;
  border-radius: 10px;
  background-color: $common-bg;
  padding: 12px;
}
.p-descr{
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 ;
}
.basic-and-recomm-title {
  text-align: center;
  color: $font-black;
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 27px;
  margin-bottom: 24px;
}
.basic-and-recomm-props {
  height: 22px;
  width: 240px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: right;
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
}
.basic-and-recomm-props-descriprion {
  height: 22px;
  width: 99px;
  color: #546C92;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 3px;
}
.recommendation-block {
  height: 115px;
  width: 376px;
  border-radius: 10px;
  padding: 12px;
}
.recommendation-block.success {
  background-color: rgba(167,220,89,0.15);
}
.recommendation-block.reject {
  background-color: #FFF0E5;
}
.recommendation-block-text {
  height: 27px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: -0.35px;
  line-height: 27px;
  margin-top: 32px;
  margin-right: 68px;
  /*background: url('../assets/icn_arrow_rounded_Color.svg') no-repeat center ;*/
}
.recommendation-block-icon {
  height: 50px;
  width: 50px;
  border: 3px solid #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(26,41,60,0.15);
}

.sign-up-title {
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin-bottom: 2px;
}
.sign-up-subtitle {
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 20px;
}
.sign-up-form {
  width: 544px;
  margin: 0 auto;
}
.socialite-btn {
  color: $font-black;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  height: 48px;
  width: 264px;
  border-radius: 6px;
  background-color: $common-bg;
  border: 1px solid $common-bg;
  margin-right: 16px;
}
.socialite-btn:last-of-type {
  margin-right: 0;
}

.or-block {
  display: block;
  height: 60px;
  width: 100%;
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
  position: relative;
  margin: 0 auto;
}
.or-block:before,
.or-block:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 5;
  height: 1px;
  width: 188px;
  background-color: #D2D9E2;
  top: 24px;
}
.or-block:before {
  left: 0;
}
.or-block:after {
  right: 0;
}
.sign-up-first-name,
.sign-up-last-name,
.sign-up-email {
  height: 48px;
  width: 264px;
  border-radius: 6px;
  background-color: $common-bg;
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  padding: 0 16px;
  border: 1px solid $common-bg;
  outline: none;
}
.sign-up-first-name::placeholder,
.sign-up-last-name::placeholder,
.sign-up-email::placeholder {
  color: $font-gray;
}
.sign-up-first-name:focus,
.sign-up-last-name:focus,
.sign-up-email:focus {
  outline: none;
  background-color: #FFFFFF;
  border: 1px solid #D2D9E2;
}
.sign-up-first-name {
  margin-right: 16px;
}
.sign-up-email {
  width: 100%;
  margin-top: 16px;
}
.btn-default {
  width: 264px;
  display: block;
  margin: 24px auto 0;
}
.justify-content-between {
  justify-content: space-between !important;
}

.margin-left-38 {
  margin-left: 38px;
}
.flex-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.sign-up {
   height: 412px;
}
.full-rep {
   height: 168px;
}
.small-block{
  display: none;
}

@media screen and (max-width: 768px) {
  .speedometer-arrow{
    width: 88px;
    left: 86px;
  }
  .wrap-top-blocks {
    margin-top: -24px;
    padding: 24px;
  }
  .rec-wrap{
    margin-top: 24px;
  }
  .block-rect{
    height: 421px;
    width: 348px;
    padding: 20px;
    margin-bottom: 0;
  }
  .assess-level-text {
    font-size: 12px;
    line-height: 22px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .margin-left-38{
    margin-left: 0
  }

  .wrap-comparison-level-percent {
    height: 115px;
    width: 308px;
    border-radius: 10px;
    padding: 12px;
    margin-top: 12px;
  }
  .wrap-low-blocks{
    margin-top: -24px;
    padding: 24px;
    flex-direction: column;
  }
  .full-rep{
    width:720px;
    height: 164px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  .small-block{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 70px;
    width: 720px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(26, 41, 60, 0.1);
    padding: 13px 200px;
    margin-bottom: 24px;
  }
  .btn-clss{
    width: 320px;
    display: block;
    margin: 0;
  }
  .basic-and-recomm{
    height: 202px;
    width: 100%;
  }
  .basic-and-recomm-props-descriprion {
    width: 118px;
  }
  .basic-and-recomm-props{
    width: 208px;
  }
  .basic-and-recomm-props-wrap{
    width: 100%;
  }
  .recommendation-block{
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .basic-and-recomm-title{
    margin-bottom: 20px;
  }
  .rec-wrap{
    margin-top: 0;
  }
  .speedometer-arrow{
    width: 95px;
    left: 96px;
  }
  .wrap-top-blocks {
    margin-top: -24px;
    padding: 0px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .comparison{
    height: 353px;
    width: 390px;
    margin-left: 0;
    margin-top: 6px;
  }

  .block-rect{
    border-radius: 0px;
    padding: 16px;
    width: 390px;
    margin-right: auto;
    margin-left: auto;
  }
  .btn-clss{
    width: 358px;
  }
  .assess-level{
    margin-top: 24px;
    height: 373px;
  }
  .small-block{
    width: 390px;
    padding: 16px 16px;
    border-radius: 0px;
    margin-top: 8px;
    margin-bottom: 0px;
    margin-right: auto;
    margin-left: auto;
  }
  .wrap-low-blocks{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    margin-top: 8px;
  }
  .full-rep{
    width: 390px;
    height: 234px;
    margin-bottom: 20px;
  }

  .wrap-comparison-level-percent {
    height: 115px;
    width: 358px;
    border-radius: 0px;
    padding: 12px;
    margin-top: 12px;
  }
  .assess-level-text{
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .margin-left-38{
    margin-left: 20px;
  }

  .basic-and-recomm-props-wrap{
    width: 358px;
  }
  .basic-and-recomm{
    margin-bottom: 8px;
  }
  .recommendation-block{
    width: 358px;
  }
}

@media screen and (max-width: 375px) {
  .speedometer-arrow{
    width: 90px;
    left: 89px;
  }
  .block-rect {
    border-radius: 0;
    padding: 16px;
    width: 375px;
    height: 351px;
  }
  .assess-level-title{
    font-size: 16px;
    line-height: 24px;
  }
  .assess-level-subtitle{
    font-size: 11px;
    line-height: 15px;
  }
  .assess-level-text{
    font-size: 11px;
    line-height: 22px;
  }
  .comparison-level-title{
    font-size: 16px;
    line-height: 24px;
  }
  .comparison-level-subtitle{
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 16px;
  }
  .wrap-comparison-level-percent{
    width: 343px;
    border-radius: 10px;
    height: 100px;
  }
  .small-block{
    width: 375px;
  }
  .btn-clss{
    width: 343px;
  }
  .basic-and-recomm-title{
    font-size: 16px;
    line-height: 24px;
  }
  .basic-and-recomm{
    height: 186px;
  }
  .recommendation-block-text{
    font-size: 16px;
    line-height: 27px;
  }
  .rectangle-square{
    height: 76px;
    width: 76px;
    border-radius: 10px;
  }
  .icon-recommend {
    margin: 9px;
  }
  .recommendation-block{
    height: 100px;
    width: 343px;
  }
  .full-rep{
    height: 203px;
  }
  .basic-and-recomm-props-wrap {
   width: 343px;
  }

  .comparison{
    height: 322px;
  }
  .comparison-level-percent{
    font-size: 28px;
    line-height: 27px
  }
}
@media screen and (max-width: 320px) {
  .block-rect {
    width: 320px;
    height: 351px;
  }
  .schkala{
    width: 288px;
  }
  .speedometer-arrow{
    width: 80px;
    left: 75px;
  }
  .assess-level-text{
    flex-direction: column;
  }
  .margin-left-38{
    margin-left: 0;
  }
  .assess-level{
    height: 400px;
  }
  .wrap-comparison-level-percent{
    width: 288px;
    border-radius: 10px;
    height: 104px;
  }
  .small-block{
    width: 320px;
  }
  .btn-clss {
    width: 288px;
  }
  .basic-and-recomm-props-wrap{
    width:288px
  }
  .basic-and-recomm-props-descriprion{
    width: 125px;
  }
  .basic-and-recomm{
    height: 186px;
  }
  .basic-and-recomm-title{
    margin-bottom: 16px;
  }
  .recommendation-block{
    width: 288px;
  }
  .full-rep{
    height: 203px;
  }
}
</style>