<template>
  <button class="btn-default"
          @click="$emit('click')"
          :disabled="disabled"
          :class="btnClass">{{ text }} <img v-if="need_forward" src="../assets/icn_forward.svg" class="forward" alt=""></button>
</template>

<script>
export default {
  name: "ButtonDefault",
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    btnClass: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    need_forward: {
      type: Boolean,
      required: false,
      default: true
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.btn-default {
  position: relative;
  height: 44px;
  width: 608px;
  border-radius: 8px;
  background-color: $font-blue;
  border: 1px solid $font-blue;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 24px;
}
button:disabled,
button[disabled]{
  background-color: rgba(205,210,217,1);
  border: none;
  color: #FFFFFF;
}
button:focus {outline: none;}
.forward{
  height: 10px;
  width: 16px;
  color: #FFFFFF;
  position: absolute;
  top: 16px;
  right: 16px;
  display: none;
}
.btn-default:hover:enabled  {
  background: $button-hover;
}
.btn-default:hover:enabled  .forward{
  display: block;
}
@media screen and (max-width: 420px) {
  .btn-default{
    width: 358px;
  }
  .btn-default:hover:enabled  {
    background: $font-blue;
  }
  .btn-default:hover:enabled  .forward{
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .btn-default{
    width: 343px;
  }
  .btn-default:hover:enabled  {
    background: $font-blue;
  }
  .btn-default:hover:enabled  .forward{
    display: none;
  }
}
@media screen and (max-width: 320px) {
  .btn-default{
    width: 288px;
  }
  .btn-default:hover:enabled  {
    background: $font-blue;
  }
  .btn-default:hover:enabled  .forward{
    display: none;
  }
}
</style>