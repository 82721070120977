<template>
  <div>
    <Navbar></Navbar>
    <StepsContainer>
      <router-view/>
    </StepsContainer>
  </div>
</template>

<script>
import Navbar  from '../components/NavBar.vue';
import StepsContainer from '../components/StepsContainer';
import axios from "axios";
export default {
name: "StepWrap",
  components: {
    Navbar,
    StepsContainer
  },
  data: () => ({
    // cors_url : 'https://cors-anywhere.herokuapp.com/',
    cors_url : '',
  }),
  created(){
    this.getData()
  },
  methods:{
    getData(){
      console.log('stepwrapCreated');
      this.getCoords()
      this.Prices()

    },

   async getCoords(){
     let url = `https://old.portal.mgnyconsulting.com:8091/API/PublicPortal/getBBLEGeoData/${this.$route.params.id}`
     const { data } = await axios.get(this.cors_url + url);
     this.$store.commit('setGoogleCords', {latitude: data['Latitude'], longitude: data['Longitude']})
   },
    async Prices(){
      let url = `https://old.portal.mgnyconsulting.com:8091/API/PublicPortal/getBBLEData`
     const { data } = await axios.get(this.cors_url + url + '/' +  this.$route.params.id) ;
     this.$store.commit('changeInputRangeParams', {name: 'income', value: Number(data['Income'])})
     this.$store.commit('changeInputRangeParams', {name: 'expenses', value: Number(data['Expense'])})
     this.$store.commit('changeInputRangeParams', {name: 'zoning', value: data['Zoning']})
     this.$store.commit('changeInputRangeParams', {name: 'area', value: Number(data['GrossSquare'])})

    // this.$store.commit('setPrices', {
    //    income: data['Income'],
    //    expenses: data['Expense']  ,
    //    zoning: data['Zoning']  ,
    //    area: data['GrossSquare']
    //  })
   },


  }

}
</script>

<style scoped>

</style>