<template>
  <div class="wrapper-tax-purpose mx-auto">
    <h4 class="tax-purpose-title">What is your relationship to this property?</h4>
    <h2 class="tax-purpose-hint" :class="validation_error ? 'error' : ''">Choose one answer from several</h2>

    <div class="row">
      <div class="wrapper-checkbox col-lg-4 col-xs-6 ">
        <input type="radio" id="owner" name="properties" @click="check_count++" v-model="checkedProperty" value="owner">
        <label for="owner">Owner</label>
        <span class="radio-checked-detector" @click="checkedProperty = 'owner'; check_count++"></span>
      </div>
      <div class="wrapper-checkbox col-lg-4 col-xs-6">
        <input type="radio" id="net_lessee" name="properties" @click="check_count++" v-model="checkedProperty" value="net_lessee">
        <label for="net_lessee">Net lessee</label>
        <span class="radio-checked-detector" @click="checkedProperty = 'net_lessee'; check_count++" ></span>
      </div>
      <div class="wrapper-checkbox col-lg-4 col-xs-6">
        <input type="radio" id="broker" name="properties"  @click="check_count++" v-model="checkedProperty" value="broker">
        <label for="broker">Broker</label>
        <span class="radio-checked-detector" @click="checkedProperty = 'broker' ; check_count++"></span>
      </div>
      <div class="wrapper-checkbox col-lg-4 col-xs-6">
        <input type="radio" id="bank_lender" name="properties" @click="check_count++" v-model="checkedProperty" value="bank_lender">
        <label for="bank_lender">Bank / Lender</label>
        <span class="radio-checked-detector" @click="checkedProperty = 'bank_lender' ; check_count++"></span>
      </div>
      <div class="wrapper-checkbox col-lg-4 col-xs-6">
        <input type="radio" id="tenant" name="properties" @click="check_count++" v-model="checkedProperty" value="tenant">
        <label for="tenant">Tenant</label>
        <span class="radio-checked-detector" @click="checkedProperty = 'tenant' ; check_count++"></span>
      </div>
      <div class="wrapper-checkbox col-lg-4 col-xs-6">
        <input type="radio" id="future_owner" name="properties" @click="check_count++" v-model="checkedProperty" value="future_owner">
        <label for="future_owner">Future owner</label>
        <span class="radio-checked-detector" @click="checkedProperty = 'future_owner' ; check_count++"></span>
      </div>

      <div class="wrapper-checkbox col-lg-4 col-xs-6">
        <input type="radio" id="accountant" name="properties" @click="check_count++" v-model="checkedProperty" value="accountant">
        <label for="accountant">Accountant</label>
        <span class="radio-checked-detector" @click="checkedProperty = 'accountant' ; check_count++"></span>
      </div>
      <div class="wrapper-checkbox col-lg-4 col-xs-6">
        <input type="radio" id="attorney" name="properties" @click="check_count++" v-model="checkedProperty" value="attorney">
        <label for="attorney">Attorney</label>
        <span class="radio-checked-detector" @click="checkedProperty = 'attorney' ; check_count++"></span>
      </div>
      <div class="wrapper-checkbox col-lg-4 col-xs-6 other">
        <input type="radio" id="other" name="properties" @click="check_count++" v-model="checkedProperty" value="other">
        <label for="other">Other</label>
        <span class="radio-checked-detector" @click="checkedProperty = 'other' ; check_count++"></span>
      </div>
    </div>
    <div class="wrapper-button">
      <ButtonDefault @click="handleClickResult()" text="Done!"/>
    </div>
  </div>
</template>

<script>
import ButtonDefault from "@/components/ButtonDefault";
import { mapGetters } from "vuex";

export default {
  name: "RelationshipToProperty",
  components: {
    ButtonDefault,
  },
  computed: {
    ...mapGetters({
      relationshipToProperty: 'get_relationshipToProperty'
    })
  },
  created() {
    console.log('created' , this.relationshipToProperty);
  },
  beforeDestroy() {
    this.check_count = 0
  },
  watch: {
    checkedProperty: {
      handler(newVal) {
        console.log('checkedProperty' , newVal);
        this.$store.commit('changeInputRangeParams',{name: 'relationshipToProperty', value: newVal})
      }
    },
    relationshipToProperty: {
      immediate: true,
      handler(newVal) {
        console.log('relationshipToProperty' , newVal);
        this.checkedProperty = newVal
      }
    }
  },
  data: () => ({
    checkedProperty: null,
    validation_error: false,
    check_count:0
  }),
  methods:{
    validate(){
      if(this.checkedProperty)  {
        this.validation_error = false
        return true
      }else{
        this.validation_error = true
        return false
      }
    },
    track(){
      this.$gtag.event('Radio button change', {
        'event_category': "relationship-to-property",
        'value': this.check_count
      })
    },
    async handleClickResult(){
      this.track()
      if(!this.validate()){return  }
      this.$store.dispatch('SendInfo')
      const res = {}
      res.status = 'ok'
      console.log(res.status);
      if(res.status === 'ok'){
        this.$router.push({name :'vacancies.last.12.month'})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
.wrapper-checkbox {
  height: 48px;
  width: 274px;
  border-radius: 6px;
  position: relative;
  margin-bottom: 16px;
  padding: 0 7px 0 7px;
}
.wrapper-checkbox:last-of-type {
  margin-right: 0;
}
.wrapper-checkbox input {
  display: none;
}
.wrapper-checkbox label {
  display: block;
  height: 48px;
  padding: 12px 16px;
  color: $font-black;
  font-family: "Open Sans";
  border-radius: 6px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  background-color: $common-bg;
  border: 1px solid $common-bg;
}
.radio-checked-detector {
  display: block;
  height: 24px;
  width: 24px;
  border: 1px solid #D2D9E2;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: 12px;
}
.wrapper-checkbox:hover label {
  background-color: transparent;
  border: 1px solid $white-color;
}
//.wrapper-checkbox:hover .radio-checked-detector {
//  border: 1px solid $font-black;
//}
input[type=radio]:checked + label{
  background-color: $font-blue;
  color: #ffffff;
}
input[type=radio]:checked + label + .radio-checked-detector{
  background: url('../assets/radio_on_Color.svg') no-repeat center ;
  border-color: #436faa;
}

.checkbox-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 16px;
}

.checkbox-row:last-of-type {
  margin-bottom: 0;
}

.wrapper-tax-purpose {
  height: 386px;
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26,41,60,0.1);
  padding: 24px;
}
.tax-purpose-hint{
  height: 19px;
  width: 100%;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 24px;
}

 .error {
  color: #d91111!important;
  text-decoration: underline ;
}
.tax-purpose-title {
  height: 33px;
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  width: 100%;
  margin-bottom: 3px;
}
.tax-purpose-wrap{
  padding: 19px 20px;
  height: 120px;
  width: 824px;
  border-radius: 10px;
  background-color: $common-bg;
  margin-bottom: 8px;
  border: 1px solid $common-bg;
}
.tax-purpose-wrap:hover {
  border: 1px solid rgba(11,40,78,0.2);
  border-radius: 10px;
  background-color: transparent;
}
.tax-purpose-wrap:hover .tax-purpose-sum{
  color: #082E60;
}
.tax-purpose-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.tax-purpose-image,
.tax-purpose-text {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}
.tax-purpose-text {
  color: $font-black;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0;
  margin-left: 20px;
}
.tax-purpose-sum {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  text-align: right;
  color: $font-blue;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 0;
}
.wrapper-button {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 23px;
}
.wrapper-button > button {
  margin-top: 0;
  /*margin-right: 16px;*/
  width: 264px;
}

@media screen and (max-width: 768px) {
  .wrapper-checkbox {
    padding: 0 7px 0 12px;
    width: 234px;
  }
  .wrapper-tax-purpose {
    height: 353px;
    width: 720px;
    padding: 20px;
  }
  .wrapper-button {
    margin-top: 20px;
  }
  .tax-purpose-hint{
    margin-bottom: 20px;
  }
  .tax-purpose-title {
    line-height: 30px;
  }
}
@media screen and (max-width: 420px) {
  .wrapper-tax-purpose {
    height: 464px;
    width: 390px;
    padding: 16px;
    border-radius: 0;
  }
  .tax-purpose-title {
    width: 227px;
    height: 56px;
    font-size: 19px;
    letter-spacing: 0;
    line-height: 28px;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper-checkbox {
    height: 48px;
    width: 181px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 8px;
    padding: 0 8px 0 0;
  }
  .row{
    padding: 0 8px 0 16px;
  }
  .other{
    width: 362px;
  }
  .wrapper-button {
    margin-top: 10px;
    width: unset;
  }
  .wrapper-button > button {
    width: 100%
  }
}
@media screen and (max-width: 375px) {
  .row{
    padding: 16px 0 8px 8px;
  }
  .wrapper-tax-purpose {
    height: 423px;
    width: 375px;
    border-radius: 0;
  }
  .tax-purpose-title{
    font-size: 16px;
    line-height: 24px;
    width: 317px;
    height: 24px;
  }
  .tax-purpose-hint{
    margin-bottom: 0px;
  }
  .wrapper-checkbox {
    height: 48px;
    width: 178px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 8px;
    padding: 0px 8px 0 8px;
  }
  .other{
    width: 359px;
  }
  .wrapper-button > button {
    width: 343px;
  }
  .wrapper-button {
    margin-top: 0;
  }
}
@media screen and (max-width: 320px) {
  .wrapper-tax-purpose {
    height: 453px;
    width: 320px;
    border-radius: 0;
  }
  .tax-purpose-title{
    width: 210px;
    height: 48px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .wrapper-checkbox {
    width: 154px;
  }
  .other{
    width: 304px;
  }


}
</style>